import { ICreateLog } from '../../../interfaces/request/transaction'
import { query_params } from '../../../utils/services'
import Base from '../base'

const baseUrl = '/account/v1'
const pathUrl = 'admin/gold-logs'

export const GetListGoldLog = (
  page: number,
  limit: number,
  search?: string,
  createdAtFrom?: number,
  createdAtTo?: number,
  userID?: number
): Promise<any> => {
  const params = query_params({
    page,
    limit,
    search,
    createdAtFrom,
    createdAtTo
  })

  return Base(baseUrl).get(
    `${pathUrl}${userID ? `/user/${userID}` : ''}?${params}`
  )
}

export const GetDetailGoldLog = (id: number): Promise<any> => {
  return Base(baseUrl).get(`${pathUrl}/transaction/${id}`)
}

export const CreateGoldLog = (params: ICreateLog): Promise<any> => {
  return Base(baseUrl).post(`${pathUrl}`, params)
}
