import { stringify } from 'query-string'
import Base from './base'
import ILauncher, { ILauncherContent } from '../../interfaces/models/launcher'

export default {
  GetList: (
    gameCode?: string,
    countryCode?: string,
    page?: number,
    limit?: number
  ): Promise<any> => {
    const params = {
      gameCode,
      countryCode,
      page,
      limit
    }
    return Base('/account/v1').get(
      `/admin/game-launcher-data?${stringify(params)}`
    )
  },
  GetDetail: (id: number): Promise<any> => {
    return Base('/account/v1').get(`/admin/game-launcher-data/${id}`)
  },
  Create: (data: ILauncher): Promise<any> => {
    return Base('/account/v1').post(`/admin/game-launcher-data`, data)
  },
  Update: (data: ILauncher): Promise<any> => {
    return Base('/account/v1').put(`/admin/game-launcher-data/${data.id}`, data)
  },
  UpdateContent: (id: string, content: ILauncherContent): Promise<any> => {
    return Base('/account/v1').put(`/admin/game-launcher-data/content/${id}`, {
      ...content
    })
  },
  Delete: (id: number): Promise<any> => {
    return Base('/account/v1').delete(`/admin/game-launcher-data/${id}`)
  }
}
