import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import React from 'react'
export default function InputSearchField({
  width,
  placeholder,
  searchField,
  onChange,
  onPress
}: IPropsSearch) {
  const onInputText = (e: string) => {
    console.log(e)
    onChange(e)
  }
  return (
    <Input
      style={{ width: width ? width : '200px' }}
      placeholder={placeholder || ''}
      suffix={<SearchOutlined />}
      value={searchField}
      size='middle'
      onPressEnter={() => onPress()}
      onChange={(e: any) => onInputText(e.target.value)}
    />
  )
}

interface IPropsSearch {
  width?: string
  placeholder?: string
  searchField: string
  onChange: (e: string) => void
  onPress: () => void
}
