import { Reducer } from 'redux'

import ActionTypes from '../../constants/action-types'
import BaseStateDefault from '../../constants/state'
import { IBannerState } from '../../interfaces/states/account'
import { IBanner } from '../../interfaces/models/account/banner'

const initialState: IBannerState = {
  ...BaseStateDefault
}

export const banner: Reducer<any> = (
  state: IBannerState = initialState,
  action: any
): IBannerState => {
  switch (action.type) {
    case ActionTypes.Banner.ADMIN_BANNER_LIST_REQUEST:
    case ActionTypes.Banner.ADMIN_BANNER_DETAIL_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.Banner.ADMIN_BANNER_LIST_FAILED:
    case ActionTypes.Banner.ADMIN_BANNER_EDIT_FAILED:
    case ActionTypes.Banner.ADMIN_BANNER_CREATE_FAILED:
    case ActionTypes.Banner.ADMIN_BANNER_DETAIL_FAILED:
    case ActionTypes.Banner.ADMIN_BANNER_DELETE_FAILED: {
      return {
        ...state,
        requesting: false,
        error: action.payload.error
      }
    }
    case ActionTypes.Banner.ADMIN_BANNER_LIST_SUCCESS: {
      const { list, paging } = action.payload
      return {
        ...state,
        requesting: false,
        list: list ? list : [],
        paging: paging
      }
    }
    case ActionTypes.Banner.ADMIN_BANNER_DELETE_SUCCESS:
    case ActionTypes.Banner.ADMIN_BANNER_CREATE_SUCCESS:
    case ActionTypes.Banner.ADMIN_BANNER_DETAIL_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        requesting: false,
        data: data
      }
    }
    case ActionTypes.Banner.ADMIN_BANNER_EDIT_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        requesting: false,
        data: {
          ...state.data,
          ...data
        }
      }
    }
    case ActionTypes.Banner.ADMIN_BANNER_UPDATE_CONTENT_SUCCESS: {
      const { content } = action.payload
      return {
        ...state,
        requesting: false,
        data: {
          ...state.data,
          content: {
            ...content
          }
        } as IBanner
      }
    }
    default: {
      return state
    }
  }
}
