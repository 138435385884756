import { Reducer } from 'redux'
import actionTypes from '../../constants/action-types'

import ActionTypes from '../../constants/action-types'
import BaseStateDefault from '../../constants/state'
import IUserState from '../../interfaces/states/user'

const initialState: IUserState = {
  ...BaseStateDefault,
  groupedList: {}
}

const reducer: Reducer<any> = (
  state: IUserState = initialState,
  action
): IUserState => {
  switch (action.type) {
    case ActionTypes.User.USER_GET_PROFILE_FAILED:
    case ActionTypes.User.USER_GET_LIST_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.User.USER_GET_LIST_SUCCESS: {
      const { list, paging } = action.payload

      return {
        ...state,
        requesting: false,
        list,
        paging
      }
    }
    case ActionTypes.User.USER_GET_PROFILE_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        requesting: false,
        list: [...(state.list || [])],
        data: data
      }
    }
    case actionTypes.User.USER_GET_PROFILE_FAILED:
    case ActionTypes.User.USER_GET_LIST_FAILED: {
      const { error } = action.payload
      return {
        ...state,
        requesting: false,
        error
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
