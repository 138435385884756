import ILogin from '../../interfaces/request/login'
import Base from './base'

export default {
  SignInWithEmail: (data: ILogin): Promise<any> => {
    return Base('/account/v1').post(`public/admin/auth`, data)
  },
  GetCurrent: (): Promise<any> => {
    return Base('/account/v1').get(`/admin/users/detail`)
  }
}
