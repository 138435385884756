import IMetadata from '../interfaces/models/metadata'

const GetContentMeta = (type: string, list?: Array<IMetadata>) => {
  let contentMeta: any = []
  const meta: IMetadata | undefined = list?.find((x) => x.type === type)
  if (meta && meta.value.length > 0) {
    contentMeta = JSON.parse(meta.value) || []
  }

  return contentMeta
}

export default GetContentMeta
