/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Typography } from 'antd'
import * as React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import MainLayout from '../../components/layout'
import IMetadataState from '../../interfaces/states/metadata'
import { ReduxState } from '../../store/reducers'
import Actions from '../../store/actions'
import { useEffect } from 'react'
import { useState } from 'react'
import AdvanceMetaEditor from '../../components/advance-meta-editor'
import METADATA from '../../constants/metadata'
import IMetadata from '../../interfaces/models/metadata'
import GetContentMeta from '../../utils/metadata'

const { Text } = Typography
interface IProps {
  metadata: IMetadataState
  GetMetadata: (type: string) => void
  Update: (data: IMetadata) => void
}

const AdvanceMetaPage = (props: IProps) => {
  const { metadata, GetMetadata, Update } = props
  const params: { type: string } = useParams()
  const [AME, setAME] = useState<any>()
  const editedMetadata = GetContentMeta(params.type, metadata.list || [])

  useEffect(() => {
    if (!AME) {
      const advanceMetadataEditor = GetContentMeta(
        METADATA.ADVANCE_METADATA_EDITOR,
        metadata.list || []
      )
      const activeAME = advanceMetadataEditor?.find(
        (ame) => ame.key === params.type
      )
      setAME(activeAME)
    }
  }, [metadata.list])

  useEffect(() => {
    if (AME) {
      GetMetadata(params.type)
    }
  }, [AME])

  return (
    <MainLayout>
      {AME ? (
        <AdvanceMetaEditor
          metadata={editedMetadata}
          onUpdate={(value) => {
            const selectedMetadata = metadata.list?.find(
              (x) => x.type === params.type
            )
            if (selectedMetadata) {
              Update({ ...selectedMetadata, value: JSON.stringify(value) })
            }
          }}
          loading={metadata.requesting}
        />
      ) : (
        <Text>
          {metadata.requesting
            ? 'fetching data'
            : 'Metadata is not editable. Please contact tech team'}
        </Text>
      )}
    </MainLayout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  metadata: state.metadata
})

const mapDispatchToProps = (dispatch: any) => ({
  GetMetadata: (type: string) => dispatch(Actions.Metadata.GetList(type)),
  Update: (data: IMetadata) => dispatch(Actions.Metadata.Update(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceMetaPage)
