import { Select } from 'antd'
import { GAME_OPTIONS } from '../../constants/enum/game-type'
import { onCapitalize } from '../../utils/formatting'
import React from 'react'
import { ORDER_STATUS_OPTIONS } from '../../constants/enum/order-status'
export default function SelectOptions({
  onChange,
  selected,
  placeholder,
  filter,
  options
}: IPropsFilter) {
  const gameList = GAME_OPTIONS.map((item) => ({
    value: item,
    label: onCapitalize(item).replaceAll('_', ' ')
  })) as IOptions[]

  const statusList = ORDER_STATUS_OPTIONS.map((item) => ({
    value: item,
    label: onCapitalize(item).replaceAll('_', ' ')
  })) as IOptions[]

  const optionsList = () => {
    switch (filter) {
      case 'game':
        return gameList
      case 'status':
        return statusList
      default:
        return options
    }
  }

  return (
    <Select
      onChange={(value: any) => onChange(value)}
      value={selected}
      showArrow
      style={{ width: '160px' }}
      placeholder={placeholder || ''}
      options={optionsList()}
      mode='multiple'
      size='middle'
      maxTagCount='responsive'
    />
  )
}

interface IPropsFilter {
  onChange: (value: any) => void
  selected: string[]
  placeholder?: string
  filter?: 'game' | 'status'
  options?: IOptions[]
}

interface IOptions {
  value: string
  label: string
}
