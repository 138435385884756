import Auth from './auth'
import User from './user'
import Upload from './upload'
import Metadata from './metadata'
import TransactionLog from './transaction-log'
import NewsUpdate from './news-update'
import Banner from './banner'
import Game from './game'
import Launcher from './launcher'

export default {
  Auth,
  User,
  Upload,
  Metadata,
  TransactionLog,
  NewsUpdate,
  Banner,
  Game,
  Launcher
}
