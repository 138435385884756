import { Button, Popover, Row, Col, Avatar } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { KEY } from '../../constants'

export default function UserProfile({ SignOut }: IProfile) {
  const name = localStorage.getItem(KEY.NAME)
  const email = localStorage.getItem(KEY.EMAIL)
  const content = (
    <div>
      <Row
        className='p-1 m-1'
        align='middle'
        justify='space-between'
        style={{ background: '#F7F4FE' }}
      >
        <Col>
          <Row align='middle' gutter={6}>
            <Col>
              <Row align='middle'>
                <Avatar style={{ background: '#6320ee' }}>
                  {name?.charAt(0)}
                </Avatar>
              </Row>
            </Col>
            <Col>
              <Row>
                <Text className='text-size-12' strong>
                  {email}
                </Text>
              </Row>
              <Row>
                <Text className='text-size-12'>{name}</Text>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify='center' align='middle' className='p-2'>
        <Button
          size='small'
          color='purple'
          className='text-size-12'
          onClick={() => SignOut()}
        >
          Logout
        </Button>
      </Row>
    </div>
  )
  return (
    <div>
      <Row>
        <Popover
          content={content}
          title='Title'
          trigger='click'
          placement='bottomRight'
        >
          <Avatar style={{ background: '#6320ee' }}>{name?.charAt(0)}</Avatar>
        </Popover>
      </Row>
    </div>
  )
}

interface IProfile {
  SignOut: any
}
