/* eslint-disable react-hooks/exhaustive-deps */
import {
  Row,
  Col,
  Button,
  Input,
  Upload,
  Select,
  Tag,
  Form,
  Checkbox,
  Modal
} from 'antd'
import { connect } from 'react-redux'
// import React, { useEffect, useState } from 'react'
import Text from 'antd/lib/typography/Text'
import actions from '../../../store/actions'
import { ReduxState } from '../../../store/reducers'
import { INewsUpdateState } from '../../../interfaces/states/account'

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import CreateForm from '../form'
import { onCapitalize } from '../../../utils/formatting'
import React, { useEffect, useState } from 'react'
import Layout from '../../../components/layout'
import RoutesLabel from '../../../constants/enum/route-name'
import CustomEditor from '../../../components/custom-editor-mce'
import { useParams } from 'react-router-dom'
import Base from '../../../store/apis/base'
import { query_params } from '../../../utils/services'
// import game from '../../../constants/action-types/game'
// import { query_params } from '../../../utils/services'

const options = [
  { value: 'event' },
  { value: 'announcement' },
  { value: 'notice' }
]

const ContainerFormNews = (props: IProps) => {
  const [gameList, setGameList] = useState<any[]>([])
  const [isCreate, setIsCreate] = useState<boolean>(true)
  const [fileListMedia, setFileListMedia] = useState<any[]>([])
  const [fileList, setFileList] = useState<any[]>([])
  const [uploading, setUploading] = useState(false)
  const params: { id: string } = useParams()
  const { CreateNews, GetDetailNews, EditNews } = props
  const [form] = Form.useForm()

  const getGameList = () => {
    const baseUrl = '/account/v1'
    const pathUrl = 'admin/games'
    const params = query_params({
      page: 1,
      limit: 100
    })

    return Base(baseUrl)
      .get(`${pathUrl}?${params}`)
      .then((res) => {
        if (res?.data) {
          const concatGame = res?.data?.data.map((val) => {
            return { value: val?.gameCode, label: val?.name }
          })

          concatGame.push({ value: 'noctua', label: 'Noctua' })
          setGameList(concatGame)
        }
      })
  }

  const [mediaList, setMediaList] = useState<string[]>([])
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('')
  const [isFeatured, setIsFeatured] = useState<boolean>(false)

  const [openMedia, setOpenMedia] = useState<boolean>(false)

  const onSubmit = async (value: any) => {
    const payload = {
      isFeatured: value.isFeatured,
      gameCode: value.gameCode,
      tags: [value.tags],
      shortDesc: value?.shortDesc,
      content: {
        title: value.title,
        text: value.description,
        medias: mediaList,
        thumbnail:
          value.thumbnail ||
          'https://storage.googleapis.com/attn-platform/noctua/account/news/saint_seiya.jpeg'
      }
    }

    if (isCreate) {
      CreateNews(payload).then((res) => {
        if (res?.data?.success) {
          window.location.href = '/news-update'
        }
      })
    } else {
      EditNews(payload, parseInt(params?.id)).then((res) => {
        if (res?.data?.success) {
          window.location.href = '/news-update'
        }
      })
    }
  }

  const fetchUpload = (data: any, isUploadMedia: boolean) => {
    const mediaArray = mediaList
    return Base('/general/v1', 60000)
      .post(`/admin/uploads`, data)
      .then((res) => {
        if (res?.data?.success) {
          if (isUploadMedia) {
            setFileListMedia([])
            mediaArray.push(res?.data?.data[0]?.url)
            setMediaList(mediaArray)
          } else {
            setFileList([])
            form.setFieldsValue({ thumbnail: res?.data?.data[0]?.url })
            setThumbnailUrl(res?.data?.data[0]?.url)
          }
        }
        setUploading(false)
      })
  }

  const handleUpload = (isUploadMedia: boolean) => {
    const uploadFile = isUploadMedia ? fileListMedia[0] : fileList[0]
    const formData = new FormData()
    formData.append('names', uploadFile?.name)
    formData.append('files', uploadFile)
    formData.append('service', '1')
    formData.append('serviceKind', '8')
    fetchUpload(formData, isUploadMedia)
    setUploading(true)
  }

  const tagRender = (tagObject: any) => {
    const { label, value, closable, onClose } = tagObject
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault()
      event.stopPropagation()
    }

    return (
      <Tag
        color={'cyan'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    )
  }

  const formItemList = [
    {
      name: 'title',
      label: 'Title',
      field: <Input style={{ borderRadius: '45px' }} />,
      rules: [{ required: true, message: 'Please input news title' }]
    },
    {
      name: 'thumbnail',
      label: 'Thumbnail',
      field: <Input style={{ borderRadius: '45px' }} />,
      rules: [{ required: true, message: 'Please input image path' }]
    },
    {
      name: 'tags',
      label: 'Tags',
      field: (
        <Select
          showArrow
          tagRender={tagRender}
          style={{ width: '100%' }}
          options={options}
        />
      ),
      rules: [{ required: true, message: 'Please select tag' }]
    },
    {
      name: 'gameCode',
      label: 'Game',
      field: <Select options={gameList} />,
      rules: [{ required: true, message: 'Select transaction type' }]
    },
    {
      name: 'shortDesc',
      label: 'Short Description',
      field: <Input style={{ borderRadius: '45px' }} />,
      rules: [{ required: true, message: 'Please input Short description' }]
    },
    {
      name: 'isFeatured',
      label: 'Featured',
      field: (
        <Checkbox
          checked={isFeatured}
          onChange={() => setIsFeatured(!isFeatured)}
        />
      )
    },
    {
      name: 'description',
      label: 'Description',
      field: <CustomEditor />
    }
  ]

  useEffect(() => {
    if (params.id) {
      GetDetailNews(parseInt(params.id)).then((res) => {
        if (res?.data.success) {
          form.setFieldsValue({
            thumbnail: res?.data?.data?.content?.thumbnail,
            title: res?.data?.data?.content?.title,
            isFeatured: res?.data?.data?.isFeatured,
            tags: res?.data?.data?.tags[0],
            gameCode: res?.data?.data?.gameCode,
            description: res?.data?.data?.content?.text,
            shortDesc: res?.data?.data?.shortDesc
          })

          setThumbnailUrl(res?.data?.data?.content?.thumbnail)
          setMediaList(res?.data?.data?.content?.medias)
          setIsFeatured(res?.data?.data?.isFeatured)
        }
      })

      setIsCreate(false)
    } else {
      setIsCreate(true)
    }
    getGameList()
  }, [params?.id])

  const uploadProps: any = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])

      return false
    },
    fileList
  }

  const uploadMediaProps: any = {
    onRemove: (file) => {
      const index = fileListMedia.indexOf(file)
      const newFileList = fileListMedia.slice()
      newFileList.splice(index, 1)
      setFileListMedia(newFileList)
    },
    beforeUpload: (file) => {
      setFileListMedia([...fileListMedia, file])

      return false
    },
    fileListMedia
  }

  return (
    <Layout
      header={{
        title: RoutesLabel.NewsAndReport
      }}
      breadcrumbs={[{ label: 'Create' }]}
    >
      <Row justify='start' align='middle' gutter={[10, 10]}>
        <Text className='pb-5' strong style={{ fontSize: '20px' }}>
          {isCreate ? 'Create' : 'Update'} News and Report
        </Text>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={18}>
          <CreateForm
            onSubmit={onSubmit}
            form={form}
            layout='horizontal'
            FormItem={formItemList}
            style={{ alignItems: 'start' }}
          />
        </Col>
        <Col span={6}>
          <div
            style={{
              border: 'solid 1px lightgray',
              width: '100%',
              height: 200
            }}
          >
            <Row style={{ height: 200 }} align='middle' justify='center'>
              <Button
                onClick={() => setOpenMedia(true)}
                icon={<UploadOutlined />}
              >
                Upload Media
              </Button>
            </Row>
          </div>
        </Col>

        <Modal
          title={'Upload Media and Thumbnail'}
          visible={openMedia}
          width={722}
          onOk={() => setOpenMedia(false)}
          closable={false}
          onCancel={() => setOpenMedia(false)}
        >
          <Row gutter={[10, 10]} justify='center'>
            <Col span={14}>
              <Row
                align='middle'
                style={{
                  border: 'solid 1px lightgray',
                  padding: 15,
                  borderRadius: 8,
                  minHeight: 260
                }}
                gutter={[20, 20]}
                justify='center'
              >
                <Col span={24}>
                  <Row justify='center'>
                    <Upload {...uploadProps} maxCount={1}>
                      <Button icon={<UploadOutlined />} style={{ width: 120 }}>
                        Thumbnail
                      </Button>
                    </Upload>
                  </Row>
                </Col>
                <Col span={24}>
                  Current upload image
                  {thumbnailUrl && (
                    <Row
                      align='middle'
                      justify='space-between'
                      style={{
                        border: 'solid 1px lightgray',
                        padding: 5,
                        borderRadius: 8
                      }}
                    >
                      <Col span={16}>
                        <Row justify='center'>
                          <a onClick={() => window.open(thumbnailUrl)}>
                            <u>@Image</u>
                          </a>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row
                          justify='center'
                          className='use-pointer'
                          onClick={() => form.setFieldsValue({ thumbnail: '' })}
                        >
                          <DeleteOutlined />
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Button
                  className='mh-2'
                  type='primary'
                  onClick={() => handleUpload(false)}
                  disabled={fileList.length === 0}
                  loading={uploading}
                >
                  {uploading ? 'Uploading' : 'Start Upload'}
                </Button>
              </Row>
            </Col>

            <Col span={14}>
              <Row
                align='middle'
                style={{
                  border: 'solid 1px lightgray',
                  padding: 25,
                  borderRadius: 8,
                  minHeight: 260
                }}
                gutter={[20, 20]}
                justify='center'
              >
                <Col span={24}>
                  <Row justify='center'>
                    <Upload {...uploadMediaProps} maxCount={1}>
                      <Button icon={<UploadOutlined />} style={{ width: 120 }}>
                        Media
                      </Button>
                    </Upload>
                  </Row>
                </Col>
                <Col span={24}>
                  Current upload image
                  <div style={{ border: 'solid 1px lightgray' }}>
                    {mediaList &&
                      mediaList.map((value, index) => {
                        return (
                          <Row
                            key={index}
                            align='middle'
                            justify='space-between'
                            style={{
                              padding: 5
                            }}
                          >
                            <Col span={10}>
                              <Row justify='center'>
                                <a onClick={() => window.open(value)}>
                                  <u>@Image{index + 1}</u>
                                </a>
                              </Row>
                            </Col>
                            <Col span={8}>
                              <Row
                                justify='center'
                                className='use-pointer'
                                onClick={() => {
                                  const array = [...mediaList]
                                  array.splice(index, 1)
                                  setMediaList(array)
                                }}
                              >
                                <DeleteOutlined />
                              </Row>
                            </Col>
                          </Row>
                        )
                      })}
                  </div>
                </Col>
                <Button
                  className='mh-2'
                  type='primary'
                  onClick={() => handleUpload(true)}
                  disabled={fileListMedia.length === 0}
                  loading={uploading}
                >
                  {uploading ? 'Uploading' : 'Start Upload'}
                </Button>
              </Row>
            </Col>
          </Row>
        </Modal>
      </Row>
    </Layout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  newsUpdate: state.newsUpdate
})

const mapDispatchToProps = (dispatch: any) => ({
  CreateNews: (payload: any) => dispatch(actions.Account.CreateNews(payload)),
  GetDetailNews: (id: number) => dispatch(actions.Account.GetDetailNews(id)),
  EditNews: (payload: any, id: number) =>
    dispatch(actions.Account.EditNews(payload, id)),
  UploadFile: (data: any) => dispatch(actions.Upload.Create(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContainerFormNews)

interface IProps {
  CreateNews: (payload: any) => Promise<any>
  GetDetailNews: (id: number) => Promise<any>
  EditNews: (payload: any, id: number) => Promise<any>
  newsUpdate: INewsUpdateState
  title?: string
  filter?: boolean
  UploadFile: (data: any) => Promise<any>
}
