/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { ReduxState } from '../../store/reducers'
import { connect } from 'react-redux'
import Actions from '../../store/actions'
import METADATA from '../../constants/metadata'
import {
  Row,
  Layout,
  Menu,
  Breadcrumb,
  Typography,
  Image,
  Col,
  Spin
} from 'antd'

import { KEY } from '../../constants/index'
import Menus from '../../constants/menu'
import IBreadcrumbItem from '../../interfaces/breadcrumb-item'
import IMenuItem from '../../interfaces/menu-item'
import history from '../../utils/history'
import IMetadataState from '../../interfaces/states/metadata'
import UserProfile from '../profile'
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem'
import IAuthState from '../../interfaces/states/auth'
import { canAccess } from '../../utils'
import { IRule } from '../../interfaces/models/admin'

export interface IProps {
  auth: IAuthState
  metadata: IMetadataState
  children?: React.ReactNode
  header?: {
    title: React.ReactNode
    url?: string
  }
  SignOut: () => void
  SetMenu: (menu: Array<string>) => void
  activeSub: Array<string>
  className?: string
  breadcrumbs?: Array<IBreadcrumbItem>
  GetMetadata: () => void
  GetCurrent: () => Promise<any>
  setRuleActivePage: (rule: IRule) => void
}

const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu
const { Text } = Typography

const MainLayout = (props: IProps) => {
  const {
    SignOut,
    SetMenu,
    GetMetadata,
    GetCurrent,
    setRuleActivePage,
    auth,
    activeSub,
    header,
    breadcrumbs,
    className,
    children
  } = props
  const [activeMenu, setActiveMenu] = useState<string>(
    history.location.pathname
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const RULES_MENU = auth.data?.rules
    ?.filter((x) => x.path.kind === 'menu')
    ?.map((x) => x.path.path)
  const IS_SUPER_ADMIN = RULES_MENU?.includes('/*')
  const RULE = auth.data?.rules?.filter((f) => f.path.kind === 'menu')

  useEffect(() => {
    setIsLoading(true)
    GetMetadata()
    GetCurrent().finally(() => setIsLoading(false))

    const path = history.location.pathname.split('/')
    if (path) {
      onOpenChange([`sub-${path[1]}`])
      setActiveMenu(`/${path[1]}`)
    }
  }, [])

  useEffect(() => {
    if ((auth.data?.rules || []).length > 0) {
      // get rule by page, if super admin can access all page
      const foundRule = RULE?.find((x) =>
        //and curr path not in rules menu
        IS_SUPER_ADMIN &&
        !RULES_MENU?.some((path) => history.location.pathname.includes(path))
          ? x.path.path === '/*'
          : history.location.pathname.includes(x.path.path)
      )
      if (foundRule) {
        setRuleActivePage(foundRule)
      }
    }
  }, [auth.data])

  const onMenuClick = (menu: any) => {
    if (menu.key === 'signout') {
      SignOut()
    } else {
      setActiveMenu(menu.key)
      history.push(menu.key)
    }
  }

  const onOpenChange = (openKeys: any) => {
    const latestOpenKey = openKeys?.find(
      (key: string) => activeSub.indexOf(key) === -1
    )

    const matchedMenuKey = Menus.find(
      (m) =>
        `sub-${(m.link || m.title).replace('/', '')}`.includes(latestOpenKey) ||
        m.sub?.find((sm) =>
          `sub-${(sm.link || sm.title).replace('/', '')}`.includes(
            latestOpenKey
          )
        )
    )
    SetMenu(
      matchedMenuKey
        ? [`sub-${matchedMenuKey.link || matchedMenuKey.title}`]
        : []
    )
  }

  const renderMenu = (menus: Array<IMenuItem>) => {
    return menus.map((m) => {
      const roleString = localStorage.getItem(KEY.ROLE) || '0'
      if (canAccess(RULES_MENU as string[], m.path || '') && !m.hidden) {
        if (!m.sub) {
          return (
            <Menu.Item key={`${m.link || m.title}`}>
              <a href={m.link}>
                <Row align='middle' wrap={false}>
                  {m.icon}
                  <Text className='text-size-12 text-color-white' ellipsis>
                    {m.title}
                  </Text>
                </Row>
              </a>
            </Menu.Item>
          )
        }
        return (
          <SubMenu
            key={`sub-${m.link || m.title}`}
            title={
              <Row align='middle' wrap={false}>
                {m.icon}
                <Text className='text-size-12 text-color-white' ellipsis>
                  {m.title}
                </Text>
              </Row>
            }
          >
            {renderMenu(m.sub)}
          </SubMenu>
        )
      }
      return null
    })
  }

  return (
    <Layout className='layout'>
      <Sider
        onCollapse={() => {}}
        width={240}
        style={{ borderRight: 'solid 1px #F0F0F0' }}
      >
        <div className='sidemenu'>
          <div className='side-logo'>
            <Image
              width={126}
              src='https://storage.googleapis.com/attn-platform/website-image/logo-text-noctua.png?w=360'
              preview={false}
            />
          </div>
          <Menu
            selectedKeys={[activeMenu]}
            openKeys={activeSub}
            mode='inline'
            onClick={onMenuClick}
            className='sider-menu'
            onOpenChange={onOpenChange}
          >
            {renderMenu(Menus)}
          </Menu>
        </div>
      </Sider>

      <Layout>
        <Header className='header'>
          <Row
            align='middle'
            justify='space-between'
            className='ph-2'
            gutter={10}
          >
            <Col span={12}>
              <Row align='middle'>
                <Col>
                  <Row></Row>
                </Col>
                <Col>
                  <Row gutter={6}>
                    <Breadcrumb className='pv-2 pl-1' separator='/'>
                      <BreadcrumbItem href={header?.url}>
                        {header?.title}
                      </BreadcrumbItem>
                      {breadcrumbs?.map((x, i) => {
                        return (
                          <Breadcrumb.Item key={i.toString()} {...x}>
                            {x.label}
                          </Breadcrumb.Item>
                        )
                      })}
                    </Breadcrumb>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              className='profile'
              span={12}
              style={{ justifyContent: 'end' }}
            >
              <Row>
                <UserProfile SignOut={SignOut} />
              </Row>
            </Col>
          </Row>
        </Header>
        <Content
          className={`content ${className || ''}`}
          style={{
            paddingTop: '10px',
            paddingBottom: '10px',
            position: 'relative'
          }}
        >
          {isLoading ? (
            <div className='position-center'>
              <Row justify='center' className='mb-1'>
                <Spin size='large' />
              </Row>
              <Row justify='center'>
                <Text>Please waiting...</Text>
              </Row>
            </div>
          ) : canAccess(RULES_MENU || [], history.location.pathname) ? (
            <div className='pt-2'>{children}</div>
          ) : (
            <div className='position-center text-weight-bold'>
              <Row justify='center' className='mb-1'>
                <img
                  width={160}
                  src={'https://noctua.gg/images/no-item.webp'}
                />
              </Row>
              <Row justify='center'>Sorry, You can&apos;t access this page</Row>
            </div>
          )}
        </Content>
        <Footer className='text-center'>
          Noctua Admin ©2023 created by ATTNTech
        </Footer>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  activeSub: state.auth.activeSub,
  metadata: state.metadata
})

const mapDispatchToProps = (dispatch: any) => ({
  SignOut: () => dispatch(Actions.Auth.Signout()),
  SetMenu: (menu: Array<string>) => dispatch(Actions.Auth.SetMenu(menu)),
  GetMetadata: () =>
    dispatch(Actions.Metadata.GetList(METADATA.ADVANCE_METADATA_EDITOR)),
  GetCurrent: () => dispatch(Actions.Auth.GetCurrent()),
  setRuleActivePage: (rule: IRule) =>
    dispatch(Actions.Auth.SetRuleActivePage(rule))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
