import { IResult } from '@attn/general/dist/interfaces/api'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../../constants/action-types'
import Api from '../../apis'
import Base from '../base'
import { IGachaTokenLogState } from '../../../interfaces/states/transaction'
import {
  ICreateLog,
  IRequestList
} from '../../../interfaces/request/transaction'
import { message } from 'antd'

export const GetListGachaTokenLog =
  ({
    page,
    limit,
    search,
    gameCodes,
    createdAtFrom,
    createdAtTo,
    userID
  }: IRequestList) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.GetListGachaTokenLog(
        page,
        limit,
        search,
        gameCodes,
        createdAtFrom,
        createdAtTo,
        userID
      ),
      () => {
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_LOG_REQUEST)
        )
      },
      (res: IResult<Array<IGachaTokenLogState>>) => {
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_LOG_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_LOG_FAILED, {
            error
          })
        )
    )
  }

export const GetDetailGachaTokenLog =
  (id: number) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.GetDetailGachaTokenLog(id),
      () => {
        dispatch(
          action(
            ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_LOG_DETAIL_REQUEST
          )
        )
      },
      (res: IResult<IGachaTokenLogState>) => {
        dispatch(
          action(
            ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_LOG_DETAIL_SUCCESS,
            {
              data: res.data
            }
          )
        )
      },
      (error: string) =>
        dispatch(
          action(
            ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_LOG_DETAIL_FAILED,
            {
              error
            }
          )
        )
    )
  }

export const CreateGachaLog =
  (data: ICreateLog) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.CreateGachaLog(data),
      () => {
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_CREATE_REQUEST)
        )
      },
      (res: IResult<IGachaTokenLogState>) => {
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_CREATE_SUCCESS, {
            data: res.data,
            success: true
          })
        )
        message.success('Gacha ticket successfully send')
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_CREATE_FAILED, {
            error
          })
        )
    )
  }
