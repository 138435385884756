import { ICreateLog } from '../../../interfaces/request/transaction'
import { query_params } from '../../../utils/services'
import Base from '../base'
const baseUrl = '/account/v1'
const pathUrl = 'admin/coin-logs'

export const GetListCoinLog = (
  page: number,
  limit: number,
  search?: string,
  createdAtFrom?: number,
  createdAtUntil?: number,
  userID?: number
): Promise<any> => {
  const params = query_params({
    page,
    limit,
    search,
    createdAtFrom,
    createdAtUntil
  })

  return Base(baseUrl).get(`${pathUrl}${userID ? `/${userID}` : ''}?${params}`)
}

export const GetDetailCoinLog = (id: number): Promise<any> => {
  return Base(baseUrl).get(`${pathUrl}/transaction/${id}`)
}

export const CreateCoinLog = (params: ICreateLog): Promise<any> => {
  return Base(baseUrl).post(`${pathUrl}`, params)
}
