import { Button, Image } from 'antd'
import Text from 'antd/lib/typography/Text'
import { convertUnixTimestampToDate } from '../../utils/date'
import React from 'react'
import ENV from '../../utils/environment'

export const NewsColumns = ({ onShowingDetailTransaction }) => [
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text) => (
      <Text className='text-size-12'>
        {convertUnixTimestampToDate(text) || ''}
      </Text>
    ),
    width: 100
  },
  {
    title: 'Title',
    dataIndex: 'contentTitle',
    key: 'title',
    render: (text) => <Text className='text-size-12'>{text || ''}</Text>,
    width: 100
  },
  {
    title: `Tag`,
    dataIndex: 'tags',
    key: 'tags',
    render: (text) => <Text className='text-size-12'>{text[0]}</Text>,
    width: 100
  },
  {
    title: 'Game',
    dataIndex: 'gameCode',
    key: 'gameCode',
    render: (text) => <Text className='text-size-12'>{text}</Text>,
    width: 100
  },
  {
    title: 'Thumbnail',
    dataIndex: 'contentThumbnail',
    key: 'content',
    render: (text) => <Image src={text || ''} width={150} height={100} />,
    width: 150
  },
  {
    title: 'Url',
    dataIndex: 'slug',
    key: 'slug',
    render: (text) => (
      <a
        href={`${ENV.WEB_FRAGMENT_URL}/news/${text}`}
        rel='noreferrer'
        target='_blank'
      >{`${ENV.WEB_FRAGMENT_URL}/news/${text}`}</a>
    ),
    width: 100
  }
]
