/* eslint-disable react-hooks/exhaustive-deps */
/*eslint prefer-const: 0*/
import { useHistory, useParams } from 'react-router-dom'
import Layout from '../../components/layout'
import RoutesLabel from '../../constants/enum/route-name'
import BannerList from './list'
import React, { useEffect, useState } from 'react'
import DefaultForm from '../../components/form'
import BannerFormColumn from '../../constants/form/banner'
import { Button, Col, message, Row } from 'antd'
import { ReduxState } from '../../store/reducers'
import { connect } from 'react-redux'
import Actions from '../../store/actions'
import { IResponse } from '@attn/general/dist/interfaces/api'
import UPLOAD_SERVICE from '../../constants/upload_service'
import UPLOAD_KIND from '../../constants/upload-kind'
import IUploadRequest from '../../interfaces/request/upload-request'
import IUploadFile from '../../interfaces/models/upload-file'
import { removeEmptyValuesObj, uploadPromise } from '../../utils'
import { IBanner, IBannerContent } from '../../interfaces/models/account/banner'
import { IBannerState } from '../../interfaces/states/account'
import moment from 'moment'
import IAuthState from '../../interfaces/states/auth'
import ButtonAction from '../../components/button-action'
import USER_ACTION from '../../constants/user-action'

interface IProps {
  auth: IAuthState
  banner: IBannerState
  Upload: (data: IUploadRequest) => Promise<any>
  GetDetail: (id: number) => Promise<any>
  UpdateBanner: (data: IBanner) => Promise<any>
  CreateBanner: (data: IBanner) => Promise<any>
  UpdateBannerContent: (id: string, content: IBannerContent) => Promise<any>
}

const Banner = (props: IProps) => {
  const {
    auth,
    banner,
    Upload,
    GetDetail,
    CreateBanner,
    UpdateBanner,
    UpdateBannerContent
  } = props
  const { id }: any = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()

  useEffect(() => {
    if (parseInt(id) && id) GetDetail(id)
  }, [id])

  const onSubmit = async (value) => {
    setLoading(true)

    let images = {
      image: value.content?.image,
      imageMobile: value.content?.imageMobile,
      icon: value.content?.icon,
      logo: value.content?.gameAnnouncementContent?.logo
    }

    const filteredImages = removeEmptyValuesObj(images)

    const uploadImagePromises: Array<Promise<IUploadFile>> = []
    for (const key in filteredImages) {
      if (typeof images[key]?.[0] === 'object' && images[key]) {
        const data = {
          files: images[key]?.[0],
          names: images[key]?.[0]?.name,
          service: UPLOAD_SERVICE.GENERAL,
          serviceKind: UPLOAD_KIND.IMAGE
        } as IUploadRequest
        uploadImagePromises.push(uploadPromise(key, data, Upload))
      }
    }

    const uploadImageUrls =
      (await Promise.all(uploadImagePromises).catch(() => {})) || []

    for (const uploadImageUrl of uploadImageUrls) {
      const { name, url } = uploadImageUrl
      images = {
        ...images,
        [name]: url
      }
    }

    const data = {
      ...banner.data,
      ...value,
      gameCode: banner.data?.gameCode || 'noctua',
      activeFrom: moment(value?.activeFrom).unix(),
      activeTo: moment(value?.activeTo).unix(),
      content: undefined
    } as IBanner

    const content = {
      ...value.content,
      image: images.image,
      icon: images.icon,
      imageMobile: images.imageMobile,
      gameAnnouncementContent: {
        ...value.content?.gameAnnouncementContent,
        logo: images.logo
      }
    }

    if (data.id) {
      await UpdateBanner(data).finally(() => setLoading(false))
      setLoading(true)
      await UpdateBannerContent(data.id.toString(), content).finally(() =>
        setLoading(false)
      )
      message.success({
        content: 'Banner has been updated'
      })
    } else
      CreateBanner({
        ...data,
        content: {
          ...content
        }
      })
        .then((res: IResponse<IBanner>) => {
          if (res.data?.success) {
            message.success({
              content: 'Banner has been created'
            })
            setTimeout(() => {
              history.push('/banner')
            }, 300)
          }
        })
        .finally(() => setLoading(false))
  }

  return (
    <Layout
      header={{
        title: RoutesLabel.Banner,
        url: '/banner'
      }}
      breadcrumbs={
        id ? [{ label: banner.data?.id ? 'Edit' : 'Create' }] : undefined
      }
    >
      {id ? (
        <DefaultForm
          initialValues={{
            ...banner.data,
            activeFrom: banner.data?.activeFrom
              ? moment.unix(banner.data?.activeFrom)
              : null,
            activeTo: banner.data?.activeTo
              ? moment.unix(banner.data?.activeTo)
              : null
          }}
          formName='create-banner'
          fields={BannerFormColumn}
          onCancel={() => {}}
          layout='horizontal'
          rowProps={{
            gutter: 16
          }}
          colProps={{
            span: 12
          }}
          onFinish={(e) => onSubmit(e)}
          additionalElementForm={
            <Row justify='end' className='mt-2'>
              <Col xs={24} md={8}>
                <ButtonAction
                  level={auth.ruleActivePage.level}
                  action={id ? USER_ACTION.UPDATE : USER_ACTION.CREATE}
                  type='primary'
                  size='large'
                  htmlType='submit'
                  block
                  loading={loading}
                >
                  Save
                </ButtonAction>
              </Col>
            </Row>
          }
        />
      ) : (
        <BannerList auth={auth} filter title='Banner' />
      )}
    </Layout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  banner: state.banner,
  auth: state.auth
})

const mapDispatchToProps = (dispatch: any) => ({
  Upload: (data: IUploadRequest) => dispatch(Actions.Upload.Create(data)),
  CreateBanner: (data: IBanner) =>
    dispatch(Actions.Account.Banner.CreateBanner(data)),
  GetDetail: (id: number) =>
    dispatch(Actions.Account.Banner.GetDetailBanner(id)),
  UpdateBanner: (data: IBanner) =>
    dispatch(Actions.Account.Banner.UpdateBanner(data)),
  UpdateBannerContent: (id: string, content: IBannerContent) =>
    dispatch(Actions.Account.Banner.UpdateBannerContent(id, content))
})

export default connect(mapStateToProps, mapDispatchToProps)(Banner)
