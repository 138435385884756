import { IResult } from '@attn/general/dist/interfaces/api'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../../constants/action-types'
import Api from '../../apis'
import Base from '../base'
import { IBannerState } from '../../../interfaces/states/account'
import { message } from 'antd'
import {
  ICreateLog,
  IRequestList
} from '../../../interfaces/request/transaction'
import {
  IBanner,
  IBannerContent
} from '../../../interfaces/models/account/banner'
const GetListBanner =
  ({ page, limit, search, gameCodes }: IRequestList) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.Banner.GetListBanner(page, limit, search, gameCodes),
      () => {
        dispatch(action(ActionTypes.Banner.ADMIN_BANNER_LIST_REQUEST))
      },
      (res: IResult<Array<IBannerState>>) => {
        dispatch(
          action(ActionTypes.Banner.ADMIN_BANNER_LIST_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Banner.ADMIN_BANNER_LIST_FAILED, {
            error
          })
        )
    )
  }

const GetDetailBanner = (id: number) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Account.Banner.GetDetailBanner(id),
    () => {
      dispatch(action(ActionTypes.Banner.ADMIN_BANNER_DETAIL_REQUEST))
    },
    (res: IResult<IBannerState>) => {
      dispatch(
        action(ActionTypes.Banner.ADMIN_BANNER_DETAIL_SUCCESS, {
          data: res.data,
          success: true
        })
      )
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Banner.ADMIN_BANNER_DETAIL_FAILED, {
          error
        })
      )
  )
}

const CreateBanner = (data: IBanner) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Account.Banner.CreateBanner(data),
    () => {
      dispatch(action(ActionTypes.Banner.ADMIN_BANNER_CREATE_REQUEST))
    },
    (res: IResult<IBannerState>) => {
      dispatch(
        action(ActionTypes.Banner.ADMIN_BANNER_CREATE_SUCCESS, {
          data: res.data,
          success: true
        })
      )
      message.success('Create banner successfully')
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Banner.ADMIN_BANNER_CREATE_FAILED, {
          error
        })
      )
  )
}

const DeleteBanner = (id: number) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Account.Banner.DeleteBanner(id),
    () => {
      dispatch(action(ActionTypes.Banner.ADMIN_BANNER_DELETE_REQUEST))
    },
    (res: IResult<IBannerState>) => {
      dispatch(
        action(ActionTypes.Banner.ADMIN_BANNER_DELETE_SUCCESS, {
          data: res.data,
          success: true
        })
      )
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Banner.ADMIN_BANNER_DELETE_FAILED, {
          error
        })
      )
  )
}

const UpdateBanner = (data: IBanner) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Account.Banner.UpdateBanner(data),
    () => {
      dispatch(action(ActionTypes.Banner.ADMIN_BANNER_DELETE_REQUEST))
    },
    (res: IResult<IBannerState>) => {
      dispatch(
        action(ActionTypes.Banner.ADMIN_BANNER_DELETE_SUCCESS, {
          data,
          success: true
        })
      )
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Banner.ADMIN_BANNER_DELETE_FAILED, {
          error
        })
      )
  )
}

const UpdateBannerContent =
  (id: string, content: IBannerContent) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.Banner.UpdateBannerContent(id, content),
      () => {
        dispatch(action(ActionTypes.Banner.ADMIN_BANNER_UPDATE_CONTENT_REQUEST))
      },
      () => {
        dispatch(
          action(ActionTypes.Banner.ADMIN_BANNER_UPDATE_CONTENT_SUCCESS, {
            content,
            success: true
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Banner.ADMIN_BANNER_UPDATE_CONTENT_FAILED, {
            error
          })
        )
    )
  }

export default {
  GetDetailBanner,
  GetListBanner,
  CreateBanner,
  UpdateBanner,
  DeleteBanner,
  UpdateBannerContent
}
