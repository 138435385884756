export const onCapitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const replaceUnderscore = (text: string) => {
  return text.replace(/_/g, ' ')
}

export const onFormatGameCodeToGame = (text: string) => {
  const game = onCapitalize(text)
  return replaceUnderscore(game)
}
