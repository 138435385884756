import React from 'react'
import { TableColumnProps, Typography } from 'antd'
import JsonEditor from '../../components/json-editor'
import { isJsonString } from '../../utils'

const { Text } = Typography

const MetadataColumn = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    render: (e: string) => {
      if (e && isJsonString(e))
        return <JsonEditor value={JSON.parse(e)} mode='view' />
      return <Text>{e}</Text>
    }
  }
] as Array<TableColumnProps<any>>

export default MetadataColumn
