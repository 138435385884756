import { IResponse, IResult } from '@attn/general/dist/interfaces/api'
import { message } from 'antd'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../constants/action-types'
import Api from '../apis'
import Base from './base'
import ILauncher, { ILauncherContent } from '../../interfaces/models/launcher'

const GetList =
  (gameCode?: string, countryCode?: string, page?: number, limit?: number) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Launcher.GetList(gameCode, countryCode, page, limit),
      () => {
        dispatch(action(ActionTypes.Launcher.LAUNCHER_GET_LIST_REQUEST))
      },
      (res: IResult<Array<ILauncher>>) => {
        dispatch(
          action(ActionTypes.Launcher.LAUNCHER_GET_LIST_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Launcher.LAUNCHER_GET_LIST_FAILED, {
            error
          })
        )
    )
  }

const GetDetail = (id: number) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Launcher.GetDetail(id),
    () => {
      dispatch(action(ActionTypes.Launcher.LAUNCHER_GET_DETAIL_REQUEST))
    },
    (res: IResult<ILauncher>) => {
      dispatch(
        action(ActionTypes.Launcher.LAUNCHER_GET_DETAIL_SUCCESS, {
          data: res.data
        })
      )
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Launcher.LAUNCHER_GET_DETAIL_FAILED, {
          error
        })
      )
  )
}

const Create = (data: ILauncher) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Launcher.Create(data),
    () => {
      dispatch(action(ActionTypes.Launcher.LAUNCHER_CREATE_REQUEST))
    },
    (res: IResult<ILauncher>) => {
      dispatch(
        action(ActionTypes.Launcher.LAUNCHER_CREATE_SUCCESS, {
          data: res.data
        })
      )
      message.success('Launcher successfully created')
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Launcher.LAUNCHER_CREATE_FAILED, {
          error
        })
      )
  )
}

const Update = (data: ILauncher) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Launcher.Update(data),
    () => {
      dispatch(action(ActionTypes.Launcher.LAUNCHER_UPDATE_REQUEST))
    },
    (res: IResult<ILauncher>) => {
      dispatch(
        action(ActionTypes.Launcher.LAUNCHER_UPDATE_SUCCESS, {
          data: res.data
        })
      )
      message.success('Launcher successfully updated')
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Launcher.LAUNCHER_UPDATE_FAILED, {
          error
        })
      )
  )
}

const Delete = (id: number) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Launcher.Delete(id),
    () => {
      dispatch(action(ActionTypes.Launcher.LAUNCHER_DELETE_REQUEST))
    },
    () => {
      dispatch(
        action(ActionTypes.Launcher.LAUNCHER_DELETE_SUCCESS, {
          id
        })
      )
      message.success('Launcher was deleted')
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Launcher.LAUNCHER_DELETE_FAILED, {
          error
        })
      )
  )
}

const UpdateContent =
  (id: string, content: ILauncherContent) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Launcher.UpdateContent(id, content),
      () => {
        dispatch(action(ActionTypes.Banner.ADMIN_BANNER_UPDATE_CONTENT_REQUEST))
      },
      () => {
        dispatch(
          action(ActionTypes.Banner.ADMIN_BANNER_UPDATE_CONTENT_SUCCESS, {
            content,
            success: true
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Banner.ADMIN_BANNER_UPDATE_CONTENT_FAILED, {
            error
          })
        )
    )
  }

export default {
  GetList,
  GetDetail,
  Create,
  Update,
  Delete,
  UpdateContent
}
