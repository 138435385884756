import { Reducer } from 'redux'

import ActionTypes from '../../constants/action-types'
import BaseStateDefault from '../../constants/state'
import IUploadState from '../../interfaces/states/upload'

const initialState: IUploadState = {
  ...BaseStateDefault
}

const reducer: Reducer<any> = (
  state: IUploadState = initialState,
  action: any
): IUploadState => {
  switch (action.type) {
    case ActionTypes.Upload.UPLOAD_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.Upload.UPLOAD_FAILED: {
      return {
        ...state,
        requesting: false,
        error: action.payload.error
      }
    }
    case ActionTypes.Upload.UPLOAD_SUCCESS: {
      return {
        ...state,
        requesting: false
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
