enum UPLOAD_SERVICE {
  UNDEFINED,
  ACCOUNT,
  SHOP,
  QUEST,
  GENERAL,
  CHAT
}

export default UPLOAD_SERVICE
