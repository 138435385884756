import { IResult } from '@attn/general/dist/interfaces/api'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../constants/action-types'
import Api from '../apis'
import Base from './base'
import IUser from '../../interfaces/models/user'

const GetList =
  (page?: number, limit?: number, search?: string) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.User.GetList(page, limit, search),
      () => {
        dispatch(action(ActionTypes.User.USER_GET_LIST_REQUEST))
      },
      (res: IResult<Array<IUser>>) => {
        dispatch(
          action(ActionTypes.User.USER_GET_LIST_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.User.USER_GET_LIST_FAILED, {
            error
          })
        )
    )
  }

const GetUserProfile = (id: number) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.User.GetUserProfile(id),
    () => {
      dispatch(action(ActionTypes.User.USER_GET_PROFILE_REQUEST))
    },
    (res: IResult<IUser>) => {
      dispatch(
        action(ActionTypes.User.USER_GET_PROFILE_SUCCESS, {
          data: res.data
        })
      )
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.User.USER_GET_PROFILE_FAILED, {
          error
        })
      )
  )
}

export default {
  GetList,
  GetUserProfile
}
