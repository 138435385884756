import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../constants/action-types'
import Api from '../apis'
import Base from './base'
import IUploadRequest from '../../interfaces/request/upload-request'

const Create = (data: IUploadRequest) => (dispatch: Dispatch<AnyAction>) => {
  const formData = new FormData()
  formData.append('files', data.files)
  formData.append('names', data.names || '')
  formData.append('service', data.service.toString())
  formData.append('serviceKind', data.serviceKind.toString())

  return Base.CallAction(
    Api.Upload.Create(formData),
    () => {
      dispatch(action(ActionTypes.Upload.UPLOAD_REQUEST))
    },
    () => {
      dispatch(action(ActionTypes.Upload.UPLOAD_SUCCESS))
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Upload.UPLOAD_FAILED, {
          error
        })
      )
  )
}

export default {
  Create
}
