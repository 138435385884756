import Base from './base'

const baseUrl = '/account/v1'
export default {
  GetList: (page?: number, limit?: number, search?: string): Promise<any> => {
    return Base(baseUrl).get(
      `admin/users/users?page=${page || 1}&limit=${limit}${
        search ? `&search=${search}` : ''
      }`
    )
  },
  GetUserProfile: (id: number): Promise<any> => {
    return Base(baseUrl).get(`admin/users/user/${id}`)
  }
}
