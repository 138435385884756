import { query_params } from '../../../utils/services'
import Base from '../base'
const baseUrl = '/account/v1'
const pathUrl = 'admin/games'

export const GetListGame = (
  page: number,
  limit: number,
  search?: string,
  gameCodes?: string
): Promise<any> => {
  const params = query_params({
    page,
    limit,
    search,
    gameCodes
  })

  return Base(baseUrl).get(`${pathUrl}?${params}`)
}

export const GetDetailGame = (id: number): Promise<any> => {
  return Base(baseUrl).get(`${pathUrl}/${id}`)
}

export const CreateGame = (params: any): Promise<any> => {
  return Base(baseUrl).post(pathUrl, params)
}

export const EditGame = (params: any): Promise<any> => {
  return Base(baseUrl).put(pathUrl + '/' + params.id, params)
}

export const DeleteGame = (id: number): Promise<any> => {
  return Base(baseUrl).delete(pathUrl + '/' + id)
}
