import GeneralParticle from '@attn/general'
import { IResult } from '@attn/general/dist/interfaces/api'
import { Modal } from 'antd'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import { IS_COMMON, IS_SUPER_ADMIN, KEY } from '../../constants'
import ActionTypes from '../../constants/action-types'
import STORAGE from '../../constants/storage'
import ILogin from '../../interfaces/request/login'
import ENV from '../../utils/environment'
import history from '../../utils/history'
import Api from '../apis'
import Base from './base'
import IAdmin, { IRule } from '../../interfaces/models/admin'

const { Set, Remove } = GeneralParticle.Utils.Storage
const { confirm } = Modal

const SignInWithEmail = (data: ILogin) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Auth.SignInWithEmail(data),
    () => {
      dispatch(action(ActionTypes.Auth.SIGN_IN_WITH_EMAIL_REQUEST))
    },
    (res: IResult<IAdmin>) => {
      if (res.data) {
        Set(
          STORAGE.TOKEN + ENV.STORAGE_SUFFIX,
          res.data.token,
          ENV.STORAGE_DOMAIN
        )
        localStorage.setItem(
          KEY.ROLE,
          res?.data?.adminRoles
            ?.find((x) => x.role.id === IS_SUPER_ADMIN)
            ?.role.id.toString() || IS_COMMON.toString()
        )
        localStorage.setItem(KEY.EMAIL, res?.data?.email)
        localStorage.setItem(KEY.NAME, res?.data?.name)
        history.push('/')
        dispatch(
          action(ActionTypes.Auth.SIGN_IN_WITH_EMAIL_SUCCESS, {
            data: res.data
          })
        )
      }
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Auth.SIGN_IN_WITH_EMAIL_FAILED, {
          error
        })
      )
  )
}

const Signout = () => (dispatch: Dispatch<AnyAction>) => {
  confirm({
    title: 'Oh no!!',
    content: 'Are you sure you want to sign out from Noctua Admin?',
    onOk() {
      Remove(STORAGE.TOKEN + ENV.STORAGE_SUFFIX, ENV.STORAGE_DOMAIN)
      dispatch(action(ActionTypes.Auth.SIGN_OUT_SUCCESS))
      localStorage.clear()
      window.location.href = '/'
    },
    onCancel: () => null
  })
}

export const SetMenuSuccess = (menu: Array<string>) =>
  action(ActionTypes.Auth.SET_MENU_SUCCESS, { sub: menu })
const SetMenu = (menu: Array<string>) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(SetMenuSuccess(menu))
}

const GetCurrent = () => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Auth.GetCurrent(),
    () => {
      dispatch(action(ActionTypes.Auth.GET_CURENT_USER_REQUEST))
    },
    (res: IResult<IAdmin>) => {
      dispatch(
        action(ActionTypes.Auth.GET_CURENT_USER_SUCCESS, {
          data: res.data
        })
      )
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Auth.GET_CURENT_USER_FAILED, {
          error
        })
      )
  )
}

const SetRuleActivePage = (rule: IRule) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(
    action(ActionTypes.Auth.SET_RULE_ACTIVE_PAGE, {
      rule
    })
  )
}

export default {
  SignInWithEmail,
  Signout,
  SetMenu,
  GetCurrent,
  SetRuleActivePage
}
