import { IResult } from '@attn/general/dist/interfaces/api'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../../constants/action-types'
import Api from '../../apis'
import Base from '../base'
import { IGameState } from '../../../interfaces/states/account'
import { message } from 'antd'
import {
  ICreateLog,
  IRequestList
} from '../../../interfaces/request/transaction'

export const GetListGame =
  ({ page, limit, search, gameCodes }: IRequestList) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.GetListGame(page, limit, search, gameCodes),
      () => {
        dispatch(action(ActionTypes.Game.ADMIN_GAME_LIST_REQUEST))
      },
      (res: IResult<Array<IGameState>>) => {
        dispatch(
          action(ActionTypes.Game.ADMIN_GAME_LIST_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Game.ADMIN_GAME_LIST_FAILED, {
            error
          })
        )
    )
  }

export const GetDetailGame =
  (id: number) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.GetDetailGame(id),
      () => {
        dispatch(action(ActionTypes.Game.ADMIN_GAME_DETAIL_REQUEST))
      },
      (res: IResult<IGameState>) => {
        dispatch(
          action(ActionTypes.Game.ADMIN_GAME_DETAIL_SUCCESS, {
            data: res.data,
            success: true
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Game.ADMIN_GAME_DETAIL_FAILED, {
            error
          })
        )
    )
  }

export const CreateGame =
  (data: ICreateLog) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.CreateGame(data),
      () => {
        dispatch(action(ActionTypes.Game.ADMIN_GAME_CREATE_REQUEST))
      },
      (res: IResult<IGameState>) => {
        dispatch(
          action(ActionTypes.Game.ADMIN_GAME_CREATE_SUCCESS, {
            data: res.data,
            success: true
          })
        )
        message.success('Create game successfully')
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Game.ADMIN_GAME_CREATE_FAILED, {
            error
          })
        )
    )
  }

export const DeleteGame = (id: number) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Account.DeleteGame(id),
    () => {
      dispatch(action(ActionTypes.Game.ADMIN_GAME_DELETE_REQUEST))
    },
    (res: IResult<IGameState>) => {
      dispatch(
        action(ActionTypes.Game.ADMIN_GAME_DELETE_SUCCESS, {
          data: res.data,
          success: true
        })
      )
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Game.ADMIN_GAME_DELETE_FAILED, {
          error
        })
      )
  )
}
