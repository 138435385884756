/* eslint-disable @typescript-eslint/no-inferrable-types */

import GeneralParticle from '@attn/general'
import { IResponse } from '@attn/general/dist/interfaces/api'
import { RcFile } from 'antd/lib/upload'
import DOMPurify from 'dompurify'

import STORAGE from '../constants/storage'
import UPLOAD_KIND from '../constants/upload-kind'
import UPLOAD_SERVICE from '../constants/upload_service'
import IUploadFile from '../interfaces/models/upload-file'
import ENV from '../utils/environment'
import IUploadRequest from '../interfaces/request/upload-request'
import { NamePath } from 'antd/lib/form/interface'
import USER_ACTION from '../constants/user-action'

const { Utils } = GeneralParticle

const isAuthenticated = () => {
  const token = Utils.Storage.Get(STORAGE.TOKEN + ENV.STORAGE_SUFFIX)
  if (token) return true
  return false
}

const tiktokMediaSplitter = (value) => {
  const s = value.split('?')[0]
  return s.split('/')
}

const protocolRemover = (host: string) => {
  return host.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
}

const createObjectUrl = (src: string | RcFile | File): string => {
  if (typeof src === 'string') return src
  return URL.createObjectURL(src)
}

const uploadPromise: (
  name: string,
  data: IUploadRequest,
  UploadFile: (data: IUploadRequest) => Promise<any>
) => Promise<IUploadFile> = (name, data, UploadFile) => {
  return new Promise((resolve, reject) => {
    UploadFile(data)
      .then((res: IResponse<Array<IUploadFile>>) => {
        if (res.data?.data) {
          resolve({
            name,
            url:
              data.serviceKind === UPLOAD_KIND.MEDIA
                ? res.data?.data[0].url
                : replaceBaseUrl(res.data?.data[0].url)
          } as IUploadFile)
        } else {
          reject()
        }
      })
      .catch(() => {
        reject()
      })
  })
}

const isJsonString = (str: string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true && typeof JSON.parse(str) === 'object'
}

const Sanitize = (str: string, html: boolean = true) => {
  return (
    (DOMPurify.sanitize &&
      DOMPurify.sanitize(str, { USE_PROFILES: { html } })) ||
    ''
  )
}

const makeId = (length) => {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const isUrl = (string) => {
  try {
    return Boolean(new URL(string))
  } catch (e) {
    return false
  }
}

/**
 *
 * @param obj
 * @param name can use simple string like "id" or array string "['content', 'id']"
 * @returns
 */
const findValueByPath = (obj: any, name: any) => {
  let path

  if (typeof name === 'string') {
    if (name.startsWith("['") && name.endsWith("']")) {
      // Parse string representation of an array
      path = name.slice(2, -2).split("','")
    } else {
      // Treat as simple string
      path = [name]
    }
  } else if (Array.isArray(name)) {
    // If name is already an array, use it directly
    path = name
  } else {
    // Invalid input
    return null
  }

  return path.reduce(
    (acc, key) => (acc && acc[key] !== undefined ? acc[key] : null),
    obj
  )
}

const removeEmptyValuesObj = (obj: Record<any, any>) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_key, value]) => value !== '' && value !== null && value !== undefined
    )
  )
}

const replaceBaseUrl = (url: string) => {
  const oldBaseUrl = /^https:\/\/storage\.googleapis\.com\/attn-platform/
  const newBaseUrl = 'https://images-attn.noctuagames.com'
  return url.replace(oldBaseUrl, newBaseUrl)
}

const canAccess = (array: string[], text: string) => {
  if (array?.includes('/*')) return true
  return array?.some((path) => {
    return text.includes(path)
  })
}

const canDo = (level: string, action: USER_ACTION) => {
  return level?.includes(action)
}

export {
  isAuthenticated,
  tiktokMediaSplitter,
  protocolRemover,
  createObjectUrl,
  uploadPromise,
  isJsonString,
  Sanitize,
  makeId,
  isUrl,
  findValueByPath,
  removeEmptyValuesObj,
  replaceBaseUrl,
  canAccess,
  canDo
}
