/*eslint no-useless-escape: "off"*/
import { IField } from '../../interfaces/models/form'

const MetadataFormColumn: Array<IField> = [
  {
    label: 'ID',
    name: 'id',
    disabled: true
  },
  {
    label: 'Type',
    name: 'type',
    rules: [
      { required: true, message: 'Type is required' },
      {
        pattern: new RegExp('^\\S*$'),
        message: `blank space not allowed`
      }
    ]
  }
]

export default MetadataFormColumn
