import React from 'react'
import { Image, TableColumnProps } from 'antd'
import { convertUnixTimestampToDate } from '../../utils/date'
import { IBanner } from '../../interfaces/models/account/banner'

const BannerColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Name',
    dataIndex: ['content', 'title'],
    key: 'name'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: 'Active From',
    dataIndex: 'activeFrom',
    key: 'activeFrom',
    render: (val) => convertUnixTimestampToDate(val)
  },
  {
    title: 'Active To',
    dataIndex: 'activeTo',
    key: 'activeTo',
    render: (val) => convertUnixTimestampToDate(val)
  },
  {
    title: 'Image',
    dataIndex: 'content',
    key: 'image',
    render: (_t, r: IBanner) => (
      <Image
        src={r?.content?.image}
        width={200}
        height={100}
        className='object-fit-cover'
      />
    )
  }
] as TableColumnProps<any>[]
export default BannerColumns
