import { ICreateLog } from '../../../interfaces/request/transaction'
import { query_params } from '../../../utils/services'
import Base from '../base'
const baseUrl = '/account/v1'
const pathUrl = 'admin/gacha-token-logs'

export const GetListGachaTokenLog = (
  page: number,
  limit: number,
  search?: string,
  gameCodes?: string,
  createdAtFrom?: number,
  createdAtTo?: number,
  userID?: number
): Promise<any> => {
  const params = query_params({
    page,
    limit,
    search,
    gameCodes,
    createdAtFrom,
    createdAtTo,
    userID
  })

  return Base(baseUrl).get(`${pathUrl}?${params.toString()}`)
}

export const GetDetailGachaTokenLog = (id: number): Promise<any> => {
  return Base(baseUrl).get(`${pathUrl}/transaction/${id}`)
}

export const CreateGachaLog = (params: ICreateLog): Promise<any> => {
  return Base(baseUrl).post(`${pathUrl}`, params)
}
