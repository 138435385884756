import './styles/app.less'
import 'react-quill/dist/quill.snow.css'

import React from 'react'
import { Route, Router, Switch } from 'react-router-dom'

import PrivateRoute from './components/private-route'
import Menus from './constants/menu'
import Login from './pages/login'
import MainPage from './pages/main'
import MetadataPage from './pages/metadata'
import AdvanceMetaPage from './pages/advance-meta'
import history from './utils/history'
import IMenuItem from './interfaces/menu-item'

const NestedMenu: any = (menus: Array<IMenuItem>) =>
  menus.map((m: IMenuItem) => {
    if (!m.sub) {
      return (
        <PrivateRoute
          auth={m.auth}
          key={m.title}
          path={m.path}
          component={m.view}
        />
      )
    }
    return NestedMenu(m.sub)
  })
const App: React.FC = () => (
  <Router history={history}>
    <Switch>
      <Route path='/login' component={Login} />
      {NestedMenu(Menus)}
      <PrivateRoute path='/advance-meta/:type' component={AdvanceMetaPage} />
      <PrivateRoute path='/metadata' component={MetadataPage} />
      <PrivateRoute path='/' component={MainPage} />
    </Switch>
  </Router>
)

export default App
