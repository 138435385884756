const ADMIN_BANNER_LIST_REQUEST = 'ADMIN_BANNER_LIST_REQUEST'
const ADMIN_BANNER_LIST_SUCCESS = 'ADMIN_BANNER_LIST_SUCCESS'
const ADMIN_BANNER_LIST_FAILED = 'ADMIN_BANNER_LIST_FAILED'

const ADMIN_BANNER_CREATE_REQUEST = 'ADMIN_BANNER_CREATE_REQUEST'
const ADMIN_BANNER_CREATE_SUCCESS = 'ADMIN_BANNER_CREATE_SUCCESS'
const ADMIN_BANNER_CREATE_FAILED = 'ADMIN_BANNER_CREATE_FAILED'

const ADMIN_BANNER_EDIT_REQUEST = 'ADMIN_BANNER_EDIT_REQUEST'
const ADMIN_BANNER_EDIT_SUCCESS = 'ADMIN_BANNER_EDIT_SUCCESS'
const ADMIN_BANNER_EDIT_FAILED = 'ADMIN_BANNER_EDIT_FAILED '

const ADMIN_BANNER_DETAIL_REQUEST = 'ADMIN_BANNER_DETAIL_REQUEST'
const ADMIN_BANNER_DETAIL_SUCCESS = 'ADMIN_BANNER_DETAIL_SUCCESS'
const ADMIN_BANNER_DETAIL_FAILED = 'ADMIN_BANNER_DETAIL_FAILED'

const ADMIN_BANNER_DELETE_REQUEST = 'ADMIN_BANNER_DELETE_REQUEST'
const ADMIN_BANNER_DELETE_SUCCESS = 'ADMIN_BANNER_DELETE_SUCCESS'
const ADMIN_BANNER_DELETE_FAILED = 'ADMIN_BANNER_DELETE_FAILED'

const ADMIN_BANNER_UPDATE_CONTENT_REQUEST =
  'ADMIN_BANNER_UPDATE_CONTENT_REQUEST'
const ADMIN_BANNER_UPDATE_CONTENT_SUCCESS =
  'ADMIN_BANNER_UPDATE_CONTENT_SUCCESS'
const ADMIN_BANNER_UPDATE_CONTENT_FAILED = 'ADMIN_BANNER_UPDATE_CONTENT_FAILED '

export default {
  ADMIN_BANNER_CREATE_REQUEST,
  ADMIN_BANNER_CREATE_SUCCESS,
  ADMIN_BANNER_CREATE_FAILED,
  ADMIN_BANNER_DELETE_REQUEST,
  ADMIN_BANNER_DELETE_SUCCESS,
  ADMIN_BANNER_DELETE_FAILED,
  ADMIN_BANNER_DETAIL_REQUEST,
  ADMIN_BANNER_DETAIL_SUCCESS,
  ADMIN_BANNER_DETAIL_FAILED,
  ADMIN_BANNER_EDIT_REQUEST,
  ADMIN_BANNER_EDIT_SUCCESS,
  ADMIN_BANNER_EDIT_FAILED,
  ADMIN_BANNER_LIST_REQUEST,
  ADMIN_BANNER_LIST_FAILED,
  ADMIN_BANNER_LIST_SUCCESS,

  ADMIN_BANNER_UPDATE_CONTENT_REQUEST,
  ADMIN_BANNER_UPDATE_CONTENT_SUCCESS,
  ADMIN_BANNER_UPDATE_CONTENT_FAILED
}
