/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

export default function TableTransactions({
  requesting,
  dataList,
  pagination,
  onPagination,
  columns
}: IPropsTable) {
  return (
    <Table
      scroll={{ x: 1300 }}
      loading={requesting}
      columns={columns}
      dataSource={dataList}
      pagination={{
        showSizeChanger: false,
        current: pagination.currentPage,
        total: pagination.total,
        onChange: (page: number) => {
          onPagination(page)
        }
      }}
    />
  )
}

interface IPropsTable {
  columns: ColumnsType<any>
  requesting?: boolean
  dataList: any[] | undefined
  pagination: Pagination
  onPagination: (page: number) => void
}

interface Pagination {
  currentPage?: number
  total?: number
}
