import GeneralParticle from '@attn/general'
import { IResponse, IResult } from '@attn/general/dist/interfaces/api'
import { IRequestHandler } from '@attn/general/dist/interfaces/api/request'
import { message } from 'antd'

const CallAction = (
  api: Promise<IResponse<any>>,
  onRequested?: () => any,
  onSuccess?: (data: IResult<any>) => any,
  onFailed?: (message: string) => any,
  ctx?: any,
  notify = true
) => {
  const r: IRequestHandler = {
    onRequested,
    onSuccess,
    onFailed: (msg) => {
      if (!ctx && notify) message.error(msg)
      if (onFailed) onFailed(msg)
    }
  }
  return GeneralParticle.API.Handler(api, r)
}

export default {
  CallAction
}
