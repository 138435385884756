import {
  IBanner,
  IBannerContent
} from '../../../interfaces/models/account/banner'
import { query_params } from '../../../utils/services'
import Base from '../base'

export default {
  GetListBanner: (
    page: number,
    limit: number,
    search?: string,
    gameCodes?: string
  ): Promise<any> => {
    const params = query_params({
      page,
      limit,
      search,
      gameCodes
    })

    return Base('/account/v1').get(`admin/banners?${params}`)
  },
  GetDetailBanner: (id: number): Promise<any> => {
    return Base('/account/v1').get(`admin/banners/${id}`)
  },
  CreateBanner: (data: IBanner): Promise<any> => {
    return Base('/account/v1').post('admin/banners', data)
  },
  UpdateBanner: (data: IBanner): Promise<any> => {
    return Base('/account/v1').put(`admin/banners/${data.id}`, data)
  },
  DeleteBanner: (id: number): Promise<any> => {
    return Base('/account/v1').delete(`admin/banners/${id}`)
  },
  UpdateBannerContent: (id: string, content: IBannerContent): Promise<any> => {
    return Base('/account/v1').put(`admin/banners/content/${id}`, {
      ...content
    })
  }
}
