/*eslint no-useless-escape: "off"*/
import _ from 'lodash'
import { IField } from '../../interfaces/models/form'
import { maxSizeFile } from '../../utils/form-validator'

const LauncherFormField: Array<IField> = [
  {
    label: 'ID',
    name: 'id',
    disabled: true
  },
  {
    label: 'Launcher Image',
    type: 'image',
    name: ['content', 'launcherImage'],
    rules: [maxSizeFile(undefined, 2)]
  },
  {
    label: 'Education Video Image',
    type: 'image',
    name: ['content', 'educationalVideoImage'],
    rules: [maxSizeFile(undefined, 2)],
    key: 'educationalVideoImage'
  },
  {
    label: 'Educational Video',
    name: ['content', 'educationalVideo'],
    type: 'video',
    key: 'educationalVideo'
  },
  {
    label: 'Announce Url',
    placeholder: 'https://noctua.gg/',
    name: ['content', 'announce', 'url'],
    type: 'text'
    //rules: [{ required: true, message: 'Type is required' }]
  },
  {
    label: 'Announce Text',
    name: ['content', 'announce', 'img'],
    type: 'image'
    //rules: [{ required: true, message: 'Type is required' }]
  },
  {
    label: 'Announce Video',
    name: ['content', 'announce', 'video', 'src'],
    type: 'video'
    //rules: [{ required: true, message: 'Type is required' }]
  },
  {
    label: 'Announce Video Thumbnail',
    name: ['content', 'announce', 'video', 'img'],
    type: 'image'
    //rules: [{ required: true, message: 'Type is required' }]
  },
  {
    label: 'More',
    placeholder: 'https://noctua.gg/',
    name: ['content', 'more'],
    type: 'text'
    //rules: [{ required: true, message: 'Type is required' }]
  },
  {
    label: 'Social Media',
    type: 'multiple-form',
    name: ['content', 'socialMedias'],
    colProps: { span: 24 },
    fields: [
      {
        placeholder: 'Name',
        name: 'name',
        type: 'text',
        colProps: { span: 8 },
        rules: [{ required: true, message: 'Type is required' }]
      },
      {
        placeholder: 'Url',
        name: 'url',
        type: 'text',
        colProps: { span: 8 }
      },
      {
        placeholder: 'Image',
        name: 'img',
        type: 'image',
        colProps: { span: 8 }
      }
    ]
  },
  {
    label: 'Banner',
    type: 'multiple-form',
    name: ['content', 'banners'],
    colProps: { span: 24 },
    fields: [
      {
        placeholder: 'Url',
        name: 'url',
        type: 'text',
        colProps: { span: 12 },
        rules: [{ required: true, message: 'Type is required' }]
      },
      {
        placeholder: 'Image',
        name: 'img',
        type: 'image',
        colProps: { span: 12 },
        rules: [{ required: true, message: 'Type is required' }]
      }
    ]
  }
]

export default LauncherFormField
