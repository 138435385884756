import { applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'

import ENV from '../utils/environment'
import { rootReducer } from './reducers'

const middlewares: Array<any> = []
middlewares.push(thunkMiddleware)
if (ENV.IS_DEBUG) middlewares.push(createLogger())

export const initStore = createStore<any, any, any, any>(
  rootReducer,
  applyMiddleware(...middlewares)
)
export type RootState = ReturnType<typeof initStore.getState>
