const USER_GET_LIST_REQUEST = 'USER_GET_LIST_REQUEST'
const USER_GET_LIST_SUCCESS = 'USER_GET_LIST_SUCCESS'
const USER_GET_LIST_FAILED = 'USER_GET_LIST_FAILED'
const USER_GET_PROFILE_REQUEST = 'USER_GET_PROFILE_REQUEST'
const USER_GET_PROFILE_SUCCESS = 'USER_GET_PROFILE_SUCCESS'
const USER_GET_PROFILE_FAILED = 'USER_GET_PROFILE_FAILED'

export default {
  USER_GET_LIST_REQUEST,
  USER_GET_LIST_SUCCESS,
  USER_GET_LIST_FAILED,
  USER_GET_PROFILE_REQUEST,
  USER_GET_PROFILE_SUCCESS,
  USER_GET_PROFILE_FAILED
}
