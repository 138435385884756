import { IResult } from '@attn/general/dist/interfaces/api'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../../constants/action-types'
import Api from '../../apis'
import Base from '../base'
import {
  IGoldLogState,
  IGoldTransferState
} from '../../../interfaces/states/transaction'
import {
  ICreateLog,
  IRequestList
} from '../../../interfaces/request/transaction'
import { message } from 'antd'

export const GetListGoldLog =
  ({ page, limit, search, createdAtFrom, createdAtTo, userID }: IRequestList) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.GetListGoldLog(
        page,
        limit,
        search,
        createdAtFrom,
        createdAtTo,
        userID
      ),
      () => {
        dispatch(action(ActionTypes.TransactionLog.ADMIN_GOLD_LOG_REQUEST))
      },
      (res: IResult<Array<IGoldTransferState>>) => {
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GOLD_LOG_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GOLD_LOG_FAILED, {
            error
          })
        )
    )
  }

export const GetDetailGoldLog =
  (id: number) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.GetDetailGoldLog(id),
      () => {
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GOLD_LOG_DETAIL_REQUEST)
        )
      },
      (res: IResult<IGoldTransferState>) => {
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GOLD_LOG_DETAIL_SUCCESS, {
            data: res.data
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GOLD_LOG__DETAIL_FAILED, {
            error
          })
        )
    )
  }

export const CreateGoldLog =
  (data: ICreateLog) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.CreateGoldLog(data),
      () => {
        dispatch(action(ActionTypes.TransactionLog.ADMIN_GOLD_CREATE_REQUEST))
      },
      (res: IResult<IGoldLogState>) => {
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GOLD_CREATE_SUCCESS, {
            data: res.data,
            success: true
          })
        )
        message.success('Gold successfully send')
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GOLD_CREATE_FAILED, {
            error
          })
        )
    )
  }
