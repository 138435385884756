import GeneralParticle from '@attn/general'
import { IRequestFetch } from '@attn/general/dist/interfaces/api/request'

import STORAGE from '../../constants/storage'
import ENV from '../../utils/environment'

const { Utils, API } = GeneralParticle

const Base = (service: string, timeout?: number) => {
  const token = Utils.Storage.Get(STORAGE.TOKEN + ENV.STORAGE_SUFFIX)
  const r: IRequestFetch = {
    baseUrl: ENV.API_URL,
    token,
    service,
    timeout
  }
  return API.Fetch(r)
}

export default Base
