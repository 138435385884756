/* eslint-disable react-hooks/exhaustive-deps */
import { Button, ButtonProps } from 'antd'
import React from 'react'
import USER_ACTION from '../../constants/user-action'
import { canDo } from '../../utils'

interface IProps extends ButtonProps {
  action: USER_ACTION
  level: string
}

const ButtonAction = (props: IProps) => {
  const { action, level, disabled, children } = props

  return (
    <Button {...props} disabled={disabled || !canDo(level, action)}>
      {children}
    </Button>
  )
}

export default ButtonAction
