const ADMIN_GAME_LIST_REQUEST = 'ADMIN_GAME_LIST_REQUEST'
const ADMIN_GAME_LIST_SUCCESS = 'ADMIN_GAME_LIST_SUCCESS'
const ADMIN_GAME_LIST_FAILED = 'ADMIN_GAME_LIST_FAILED'

const ADMIN_GAME_CREATE_REQUEST = 'ADMIN_GAME_CREATE_REQUEST'
const ADMIN_GAME_CREATE_SUCCESS = 'ADMIN_GAME_CREATE_SUCCESS'
const ADMIN_GAME_CREATE_FAILED = 'ADMIN_GAME_CREATE_FAILED'

const ADMIN_GAME_EDIT_REQUEST = 'ADMIN_GAME_EDIT'
const ADMIN_GAME_EDIT_SUCCESS = 'ADMIN_GAME_EDIT'
const ADMIN_GAME_EDIT_FAILED = 'ADMIN_GAME_EDIT'

const ADMIN_GAME_DETAIL_REQUEST = 'ADMIN_GAME_DETAIL_REQUEST'
const ADMIN_GAME_DETAIL_SUCCESS = 'ADMIN_GAME_DETAIL_SUCCESS'
const ADMIN_GAME_DETAIL_FAILED = 'ADMIN_GAME_DETAIL_FAILED'

const ADMIN_GAME_DELETE_REQUEST = 'ADMIN_GAME_DELETE_REQUEST'
const ADMIN_GAME_DELETE_SUCCESS = 'ADMIN_GAME_DELETE_SUCCESS'
const ADMIN_GAME_DELETE_FAILED = 'ADMIN_GAME_DELETE_FAILED'

export default {
  ADMIN_GAME_CREATE_REQUEST,
  ADMIN_GAME_CREATE_SUCCESS,
  ADMIN_GAME_CREATE_FAILED,
  ADMIN_GAME_DELETE_REQUEST,
  ADMIN_GAME_DELETE_SUCCESS,
  ADMIN_GAME_DELETE_FAILED,
  ADMIN_GAME_DETAIL_REQUEST,
  ADMIN_GAME_DETAIL_SUCCESS,
  ADMIN_GAME_DETAIL_FAILED,
  ADMIN_GAME_EDIT_REQUEST,
  ADMIN_GAME_EDIT_SUCCESS,
  ADMIN_GAME_EDIT_FAILED,
  ADMIN_GAME_LIST_REQUEST,
  ADMIN_GAME_LIST_FAILED,
  ADMIN_GAME_LIST_SUCCESS
}
