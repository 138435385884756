import IMenuItem from '../interfaces/menu-item'
// import purchaseHistoryLayout from '../pages/exchange-reward'
// import sendPoint from '../pages/noctua-point-history/form'
// import sendGold from '../pages/noctua-gold-transaction/gold-history/form'
import { IS_SUPER_ADMIN } from './index'
import RoutesLabel from './enum/route-name'
// import users from '../pages/users'
// import pointLog from '../pages/noctua-point-history'
// import sendGacha from '../pages/exchange-reward/gacha/form'
// import GoldHistory from '../pages/noctua-gold-transaction'
// import banner from '../pages/banner'
// import games from '../pages/games'
import NewsAndReport from '../pages/news-update'
import formContainer from '../pages/news-update/form-container'
import Banner from '../pages/banner'
import Launcher from '../pages/launcher'

const Menus: Array<IMenuItem> = [
  // {
  //   title: 'Transaction',
  //   auth: IS_SUPER_ADMIN,
  //   sub: [
  //     {
  //       title: 'Gold',
  //       link: '/noctua-gold-transaction',
  //       path: '/noctua-gold-transaction',
  //       view: GoldHistory,
  //       auth: IS_SUPER_ADMIN
  //     },
  //     {
  //       title: 'Point',
  //       link: '/noctua-point-transaction',
  //       path: '/noctua-point-transaction',
  //       view: pointLog,
  //       auth: IS_SUPER_ADMIN
  //     },
  //     {
  //       title: 'Reward',
  //       link: '/purchase-history',
  //       path: '/purchase-history',
  //       view: purchaseHistoryLayout,
  //       auth: IS_SUPER_ADMIN
  //     }
  //   ]
  // },
  // {
  //   title: RoutesLabel.Users,
  //   link: '/users',
  //   path: '/users/:id?',
  //   view: users,
  //   auth: IS_SUPER_ADMIN
  // },
  // {
  //   title: RoutesLabel.Users,
  //   link: '/send-noctua-gold',
  //   path: '/send-noctua-gold/:id?',
  //   view: sendGold,
  //   auth: IS_SUPER_ADMIN,
  //   hidden: true
  // },
  // {
  //   title: RoutesLabel.Users,
  //   link: '/send-noctua-point',
  //   path: '/send-noctua-point/:id?',
  //   view: sendPoint,
  //   auth: IS_SUPER_ADMIN,
  //   hidden: true
  // },
  // {
  //   title: RoutesLabel.Users,
  //   link: '/send-gacha-ticket',
  //   path: '/send-gacha-ticket/:id?',
  //   view: sendGacha,
  //   auth: IS_SUPER_ADMIN,
  //   hidden: true
  // },
  {
    title: RoutesLabel.Banner,
    link: '/banner',
    path: '/banner/:id?',
    view: Banner,
    auth: IS_SUPER_ADMIN
  },
  {
    title: RoutesLabel.Launcher,
    link: '/launcher',
    path: '/launcher/:countryCode?/:id?',
    view: Launcher,
    auth: IS_SUPER_ADMIN
  },
  // {
  //   title: RoutesLabel.Game,
  //   link: '/game',
  //   path: '/game',
  //   view: games,
  //   auth: IS_SUPER_ADMIN
  // },
  {
    title: RoutesLabel.NewsAndReport,
    link: '/news-update',
    path: '/news-update',
    view: NewsAndReport,
    auth: IS_SUPER_ADMIN
  },
  {
    title: RoutesLabel.NewsAndReport,
    link: '/news-update-form',
    path: '/news-update-form/:id?',
    view: formContainer,
    auth: IS_SUPER_ADMIN,
    hidden: true
  }
]

export default Menus
