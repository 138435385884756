/*eslint no-useless-escape: "off"*/
import _ from 'lodash'
import { IField } from '../../interfaces/models/form'
import { maxSizeFile } from '../../utils/form-validator'

export enum BANNER_TYPE {
  HOME = 'home',
  TOPUP = 'topup',
  DESKTOP_HOME = 'desktop-home'
}

const bannerTypesArray = Object.entries(BANNER_TYPE).map(([key, value]) => ({
  id: value,
  name: _.capitalize(key.toLowerCase().replace(/_/g, ' '))
})) as any

const BannerFormColumn: Array<IField> = [
  {
    label: 'ID',
    name: 'id',
    disabled: true
  },
  {
    label: 'Name',
    type: 'text',
    name: ['content', 'title'],
    rules: [{ required: true, message: 'Type is required' }]
  },
  {
    label: 'Url',
    type: 'text',
    name: ['content', 'url'],
    rules: [{ required: true, message: 'Type is required' }]
  },
  {
    label: 'Type',
    type: 'select',
    name: 'type',
    options: bannerTypesArray,
    rules: [{ required: true, message: 'Type is required' }]
  },
  {
    label: 'Start At',
    type: 'date-time',
    name: 'activeFrom',
    rules: [{ required: true, message: 'Type is required' }]
  },
  {
    label: 'End At',
    type: 'date-time',
    name: 'activeTo',
    rules: [{ required: true, message: 'Type is required' }]
  },
  {
    label: 'Image',
    type: 'image',
    name: ['content', 'image'],
    rules: [
      { required: true, message: 'Type is required' },
      maxSizeFile(undefined, 2)
    ],
    maxFileSize: 2,
    accept: 'image/*'
  },
  {
    label: 'Image Mobile',
    type: 'image',
    name: ['content', 'imageMobile'],
    rules: [maxSizeFile(undefined, 2)],
    maxFileSize: 2,
    accept: 'image/*'
  },
  {
    label: 'Icon',
    type: 'image',
    name: ['content', 'icon'],
    rules: [maxSizeFile(undefined, 2)],
    maxFileSize: 2,
    accept: 'image/*'
  },
  {
    label: 'Logo',
    type: 'image',
    name: ['content', 'gameAnnouncementContent', 'logo'],
    rules: [maxSizeFile(undefined, 2)],
    maxFileSize: 2,
    accept: 'image/*'
  },
  {
    label: 'Title',
    type: 'text',
    name: ['content', 'gameAnnouncementContent', 'title']
  },
  {
    label: 'Button Name',
    type: 'text',
    name: ['content', 'gameAnnouncementContent', 'buttonName']
  },
  {
    label: 'Helper Button',
    type: 'text',
    name: ['content', 'gameAnnouncementContent', 'helperButton']
  },
  {
    label: 'Description',
    type: 'textarea',
    name: ['content', 'gameAnnouncementContent', 'description']
  }
]

export default BannerFormColumn
