const ADMIN_GOLD_TRANSFER_REQUEST = 'ADMIN_GOLD_TRANSFER_REQUEST'
const ADMIN_GOLD_TRANSFER_SUCCESS = 'ADMIN_GOLD_TRANSFER_SUCCESS'
const ADMIN_GOLD_TRANSFER_FAILED = 'ADMIN_GOLD_TRANSFER_FAILED'

const ADMIN_GOLD_TRANSFER_DETAIL_REQUEST = 'ADMIN_GOLD_TRANSFER_DETAIL_REQUEST'
const ADMIN_GOLD_TRANSFER_DETAIL_SUCCESS = 'ADMIN_GOLD_TRANSFER_DETAIL_SUCCESS'
const ADMIN_GOLD_TRANSFER_DETAIL_FAILED = 'ADMIN_GOLD_TRANSFER_DETAIL_FAILED'

const ADMIN_GOLD_LOG_REQUEST = 'ADMIN_GOLD_LOG_REQUEST'
const ADMIN_GOLD_LOG_SUCCESS = 'ADMIN_GOLD_LOG_SUCCESS'
const ADMIN_GOLD_LOG_FAILED = 'ADMIN_GOLD_LOG_FAILED'

const ADMIN_GOLD_CREATE_REQUEST = 'ADMIN_GOLD_CREATE_REQUEST'
const ADMIN_GOLD_CREATE_SUCCESS = 'ADMIN_GOLD_CREATE_SUCCESS'
const ADMIN_GOLD_CREATE_FAILED = 'ADMIN_GOLD_CREATE_FAILED'

const ADMIN_GOLD_LOG_DETAIL_REQUEST = 'ADMIN_GOLD_LOG_DETAIL_REQUEST'
const ADMIN_GOLD_LOG_DETAIL_SUCCESS = 'ADMIN_GOLD_LOG_DETAIL_SUCCESS'
const ADMIN_GOLD_LOG__DETAIL_FAILED = 'ADMIN_GOLD_LOG__DETAIL_FAILED'

const ADMIN_COIN_LOG_REQUEST = 'ADMIN_COIN_LOG_REQUEST'
const ADMIN_COIN_LOG_SUCCESS = 'ADMIN_COIN_LOG_SUCCESS'
const ADMIN_COIN_LOG_FAILED = 'ADMIN_COIN_LOG_FAILED'

const ADMIN_COIN_LOG_DETAIL_REQUEST = 'ADMIN_COIN_LOG_DETAIL_REQUEST'
const ADMIN_COIN_LOG_DETAIL_SUCCESS = 'ADMIN_COIN_LOG_DETAIL_SUCCESS'
const ADMIN_COIN_LOG__DETAIL_FAILED = 'ADMIN_COIN_LOG__DETAIL_FAILED'

const ADMIN_COIN_CREATE_REQUEST = 'ADMIN_COIN_CREATE_REQUEST'
const ADMIN_COIN_CREATE_SUCCESS = 'ADMIN_COIN_CREATE_SUCCESS'
const ADMIN_COIN_CREATE_FAILED = 'ADMIN_COIN_CREATE_FAILED'

const ADMIN_PURCHASE_HISTORY_REQUEST = 'ADMIN_PURCHASE_HISTORY_REQUEST'
const ADMIN_PURCHASE_HISTORY_SUCCESS = 'ADMIN_PURCHASE_HISTORY_SUCCESS'
const ADMIN_PURCHASE_HISTORY_FAILED = 'ADMIN_PURCHASE_HISTORY_FAILED'

const ADMIN_PURCHASE_HISTORY_DETAIL_REQUEST =
  'ADMIN_PURCHASE_HISTORY_DETAIL_REQUEST'
const ADMIN_PURCHASE_HISTORY_DETAIL_SUCCESS =
  'ADMIN_PURCHASE_HISTORY_DETAIL_SUCCESS'
const ADMIN_PURCHASE_HISTORY_DETAIL_FAILED =
  'ADMIN_PURCHASE_HISTORY_DETAIL_FAILED'

const ADMIN_GACHA_TOKEN_LOG_REQUEST = 'ADMIN_GACHA_TOKEN_LOG_REQUEST'
const ADMIN_GACHA_TOKEN_LOG_SUCCESS = 'ADMIN_GACHA_TOKEN_LOG_SUCCESS'
const ADMIN_GACHA_TOKEN_LOG_FAILED = 'ADMIN_GACHA_TOKEN_LOG_FAILED'

const ADMIN_GACHA_TOKEN_CREATE_REQUEST = 'ADMIN_GACHA_TOKEN_CREATE_REQUEST'
const ADMIN_GACHA_TOKEN_CREATE_SUCCESS = 'ADMIN_GACHA_TOKEN_CREATE_SUCCESS'
const ADMIN_GACHA_TOKEN_CREATE_FAILED = 'ADMIN_GACHA_TOKEN_CREATE_FAILED'

const ADMIN_GACHA_TOKEN_LOG_DETAIL_REQUEST =
  'ADMIN_GACHA_TOKEN_LOG_DETAIL_REQUEST'
const ADMIN_GACHA_TOKEN_LOG_DETAIL_SUCCESS =
  'ADMIN_GACHA_TOKEN_LOG_DETAIL_SUCCESS'
const ADMIN_GACHA_TOKEN_LOG_DETAIL_FAILED =
  'ADMIN_GACHA_TOKEN_LOG_DETAIL_FAILED'

const ADMIN_MILESTONE_REQUEST = 'ADMIN_MILESTONE_REQUEST'
const ADMIN_MILESTONE_SUCCESS = 'ADMIN_MILESTONE_SUCCESS'
const ADMIN_MILESTONE_FAILED = 'ADMIN_MILESTONE_FAILED'

const ADMIN_MILESTONE_DETAIL_REQUEST = 'ADMIN_MILESTONE_DETAIL_REQUEST'
const ADMIN_MILESTONE_DETAIL_SUCCESS = 'ADMIN_MILESTONE_DETAIL_SUCCESS'
const ADMIN_MILESTONE_DETAIL_FAILED = 'ADMIN_MILESTONE_DETAIL_FAILED'

export default {
  ADMIN_GOLD_TRANSFER_REQUEST,
  ADMIN_GOLD_TRANSFER_SUCCESS,
  ADMIN_GOLD_TRANSFER_FAILED,
  ADMIN_GOLD_TRANSFER_DETAIL_REQUEST,
  ADMIN_GOLD_TRANSFER_DETAIL_SUCCESS,
  ADMIN_GOLD_TRANSFER_DETAIL_FAILED,
  ADMIN_GOLD_LOG_REQUEST,
  ADMIN_GOLD_LOG_SUCCESS,
  ADMIN_GOLD_LOG_FAILED,
  ADMIN_GOLD_LOG_DETAIL_REQUEST,
  ADMIN_GOLD_LOG_DETAIL_SUCCESS,
  ADMIN_GOLD_LOG__DETAIL_FAILED,
  ADMIN_GOLD_CREATE_REQUEST,
  ADMIN_GOLD_CREATE_SUCCESS,
  ADMIN_GOLD_CREATE_FAILED,
  ADMIN_COIN_LOG_REQUEST,
  ADMIN_COIN_LOG_SUCCESS,
  ADMIN_COIN_LOG_FAILED,
  ADMIN_COIN_LOG_DETAIL_REQUEST,
  ADMIN_COIN_LOG_DETAIL_SUCCESS,
  ADMIN_COIN_LOG__DETAIL_FAILED,
  ADMIN_COIN_CREATE_REQUEST,
  ADMIN_COIN_CREATE_SUCCESS,
  ADMIN_COIN_CREATE_FAILED,
  ADMIN_PURCHASE_HISTORY_REQUEST,
  ADMIN_PURCHASE_HISTORY_SUCCESS,
  ADMIN_PURCHASE_HISTORY_FAILED,
  ADMIN_PURCHASE_HISTORY_DETAIL_REQUEST,
  ADMIN_PURCHASE_HISTORY_DETAIL_SUCCESS,
  ADMIN_PURCHASE_HISTORY_DETAIL_FAILED,
  ADMIN_GACHA_TOKEN_LOG_REQUEST,
  ADMIN_GACHA_TOKEN_LOG_SUCCESS,
  ADMIN_GACHA_TOKEN_LOG_FAILED,
  ADMIN_GACHA_TOKEN_CREATE_REQUEST,
  ADMIN_GACHA_TOKEN_CREATE_SUCCESS,
  ADMIN_GACHA_TOKEN_CREATE_FAILED,
  ADMIN_GACHA_TOKEN_LOG_DETAIL_REQUEST,
  ADMIN_GACHA_TOKEN_LOG_DETAIL_SUCCESS,
  ADMIN_GACHA_TOKEN_LOG_DETAIL_FAILED,
  ADMIN_MILESTONE_REQUEST,
  ADMIN_MILESTONE_SUCCESS,
  ADMIN_MILESTONE_FAILED,
  ADMIN_MILESTONE_DETAIL_REQUEST,
  ADMIN_MILESTONE_DETAIL_SUCCESS,
  ADMIN_MILESTONE_DETAIL_FAILED
}
