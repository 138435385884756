/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Button, Table, Switch, Modal } from 'antd'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import actions from '../../../store/actions'
import { ReduxState } from '../../../store/reducers'

import { IRequestList } from '../../../interfaces/request/transaction'
import { IBannerState } from '../../../interfaces/states/account'
import BannerColumns from '../../../constants/table/banner'
import { PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { IBanner } from '../../../interfaces/models/account/banner'
import IAuthState from '../../../interfaces/states/auth'
import ButtonAction from '../../../components/button-action'
import USER_ACTION from '../../../constants/user-action'

interface IProps {
  GetListBanner: (props: IRequestList) => void
  GetDetailBanner: (id: number) => void
  DeleteBanner: (id: number) => void
  banner: IBannerState
  title?: string
  filter?: boolean
  auth: IAuthState
}

const Banner = (props: IProps) => {
  const initialPage = 1

  const { GetListBanner, DeleteBanner, banner, auth } = props
  const history = useHistory()

  const onFetchBanner = (page: number) => {
    const paramsList = {
      page: page,
      limit: 8
    }

    GetListBanner(paramsList)
  }

  useEffect(() => {
    onFetchBanner(initialPage)
  }, [])

  const onDeleteBanner = (id: number) => {
    DeleteBanner(id)
    onFetchBanner(1)
  }

  const onCreateBanner = () => {
    history.push('/banner/create')
  }

  return (
    <>
      <Row gutter={16} justify='end' className='mb-2'>
        <Col>
          <ButtonAction
            level={auth.ruleActivePage.level}
            action={USER_ACTION.CREATE}
            onClick={() => onCreateBanner()}
            type='primary'
            style={{
              borderRadius: '8px'
            }}
          >
            <PlusOutlined />
            Add Banner
          </ButtonAction>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={banner?.requesting}
            columns={[
              ...BannerColumns,
              {
                title: 'Action',
                dataIndex: 'id',
                key: 'action',
                align: 'center',
                render: (_t, r: IBanner) => (
                  <Row gutter={[8, 8]} align='middle' justify='center'>
                    <Col>
                      <ButtonAction
                        level={auth.ruleActivePage.level}
                        action={USER_ACTION.DELETE}
                        ghost
                        className='border-danger-main text-color-danger-main'
                        onClick={() => {
                          Modal.confirm({
                            content: 'Are you sure want to delete this banner?',
                            onOk: () => onDeleteBanner(r.id)
                          })
                        }}
                      >
                        Delete
                      </ButtonAction>
                    </Col>
                    <Col>
                      <Button type='primary' href={`/banner/${r.id}`}>
                        Details
                      </Button>
                    </Col>
                  </Row>
                )
              }
            ]}
            dataSource={banner?.list || []}
            rowKey={(wr: IBanner) => wr.id}
            pagination={{
              hideOnSinglePage: true,
              showLessItems: true,
              pageSize: banner?.paging?.limit || 0,
              total: banner?.paging?.totalData
            }}
            onChange={(page) => {
              onFetchBanner(page.current || 1)
            }}
          />
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  banner: state.banner
})

const mapDispatchToProps = (dispatch: any) => ({
  GetListBanner: (props: IRequestList) =>
    dispatch(actions.Account.Banner.GetListBanner(props)),
  GetDetailBanner: (id: number) =>
    dispatch(actions.Account.Banner.GetDetailBanner(id)),
  DeleteBanner: (id: number) =>
    dispatch(actions.Account.Banner.DeleteBanner(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Banner)
