/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Button, Modal } from 'antd'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import Text from 'antd/lib/typography/Text'
import actions from '../../../store/actions'
import { ReduxState } from '../../../store/reducers'

import InputSearchField from '../../../components/filter/input-search-field'
import TableTransactions from '../../../components/transaction/tabel-transaction'
import { IRequestList } from '../../../interfaces/request/transaction'
import { INewsUpdateState } from '../../../interfaces/states/account'
import SelectOptions from '../../../components/filter/select-options'
import { NewsColumns } from '../../../constants/table/news-updates'
import { PlusOutlined } from '@ant-design/icons'
import { IResponse } from '@attn/general/dist/interfaces/api'
import { INewsUpdate } from '../../../interfaces/models/account/news-update'

const NewsUpdate = (props: IProps) => {
  const initialPage = 1
  const [searchField, setSearchField] = React.useState<string>('')
  const [selectedGameOptions, setSelectedGameOptions] = useState<string[]>([])

  const { GetListNews, GetDetailNews, DeleteNews, newsUpdate, title, filter } =
    props

  const onFetchBanner = (page: number) => {
    const paramsList = {
      page: page,
      limit: 10,
      gameCodes: selectedGameOptions?.join(',')
    }

    GetListNews(paramsList)
  }

  const onShowingDetailTransaction = (data: any) => {
    GetDetailNews(data?.id)
  }

  const onCreateBanner = () => {
    window.location.href = '/news-update-form'
  }

  useEffect(() => {
    onFetchBanner(initialPage)
  }, [selectedGameOptions])

  const onDelete = (id: number) => {
    DeleteNews(id).then((res: IResponse<INewsUpdate>) => {
      if (res.data?.success) onFetchBanner(initialPage)
    })
  }

  return (
    <>
      <Row className='mb-2' justify='space-between'>
        <Col>
          {title && (
            <Text className='text-size-20 text-color-primary-purple'>
              {title}
            </Text>
          )}
        </Col>
        {filter && (
          <Col>
            <Row gutter={16}>
              <Col>
                <SelectOptions
                  placeholder='select game'
                  filter='game'
                  onChange={setSelectedGameOptions}
                  selected={selectedGameOptions}
                />
              </Col>
              <Col>
                <InputSearchField
                  width={'350px'}
                  placeholder={
                    'search data by: User ID, Receipt ID, Type, Amount'
                  }
                  searchField={searchField}
                  onChange={setSearchField}
                  onPress={() => onFetchBanner(initialPage)}
                />
              </Col>
              <Col>
                <Button
                  onClick={() => onCreateBanner()}
                  type='primary'
                  style={{
                    borderRadius: '8px'
                  }}
                >
                  <PlusOutlined />
                  Add News and Updates
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          {newsUpdate && (
            <TableTransactions
              columns={[
                ...NewsColumns({ onShowingDetailTransaction }),
                {
                  title: 'Action',
                  dataIndex: 'id',
                  key: 'id',
                  align: 'center',
                  render: (text, value) => (
                    <Row gutter={[8, 8]} justify='center' align='middle'>
                      <Col>
                        <Button
                          ghost
                          className='border-danger-main text-color-danger-main'
                          onClick={() => {
                            Modal.confirm({
                              content: 'Are you sure want to delete this news?',
                              onOk: () => onDelete(value.id)
                            })
                          }}
                        >
                          Delete
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          onClick={() =>
                            window.open('/news-update-form/' + value.id)
                          }
                          type='primary'
                        >
                          Details
                        </Button>
                      </Col>
                    </Row>
                  ),
                  width: 100
                }
              ]}
              requesting={newsUpdate?.requesting}
              dataList={newsUpdate ? newsUpdate.list : []}
              pagination={{
                total: newsUpdate.paging?.totalData,
                currentPage: newsUpdate?.paging?.currentPage
              }}
              onPagination={onFetchBanner}
            />
          )}
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  newsUpdate: state.newsUpdate
})

const mapDispatchToProps = (dispatch: any) => ({
  GetListNews: (props: IRequestList) =>
    dispatch(actions.Account.GetListNews(props)),
  GetDetailNews: (id: number) => dispatch(actions.Account.GetDetailNews(id)),
  DeleteNews: (id: number) => dispatch(actions.Account.DeleteNews(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewsUpdate)

interface IProps {
  GetListNews: (props: IRequestList) => void
  GetDetailNews: (id: number) => void
  DeleteNews: (id: number) => Promise<any>
  newsUpdate: INewsUpdateState
  title?: string
  filter?: boolean
}
