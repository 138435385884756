const LAUNCHER_GET_LIST_REQUEST = 'LAUNCHER_GET_LIST_REQUEST'
const LAUNCHER_GET_LIST_SUCCESS = 'LAUNCHER_GET_LIST_SUCCESS'
const LAUNCHER_GET_LIST_FAILED = 'LAUNCHER_GET_LIST_FAILED'

const LAUNCHER_GET_DETAIL_REQUEST = 'LAUNCHER_GET_DETAIL_REQUEST'
const LAUNCHER_GET_DETAIL_SUCCESS = 'LAUNCHER_GET_DETAIL_SUCCESS'
const LAUNCHER_GET_DETAIL_FAILED = 'LAUNCHER_GET_DETAIL_FAILED'

const LAUNCHER_CREATE_REQUEST = 'LAUNCHER_CREATE_REQUEST'
const LAUNCHER_CREATE_SUCCESS = 'LAUNCHER_CREATE_SUCCESS'
const LAUNCHER_CREATE_FAILED = 'LAUNCHER_CREATE_FAILED'

const LAUNCHER_UPDATE_REQUEST = 'LAUNCHER_UPDATE_REQUEST'
const LAUNCHER_UPDATE_SUCCESS = 'LAUNCHER_UPDATE_SUCCESS'
const LAUNCHER_UPDATE_FAILED = 'LAUNCHER_UPDATE_FAILED'

const LAUNCHER_DELETE_REQUEST = 'LAUNCHER_DELETE_REQUEST'
const LAUNCHER_DELETE_SUCCESS = 'LAUNCHER_DELETE_SUCCESS'
const LAUNCHER_DELETE_FAILED = 'LAUNCHER_DELETE_FAILED'

export default {
  LAUNCHER_GET_LIST_REQUEST,
  LAUNCHER_GET_LIST_SUCCESS,
  LAUNCHER_GET_LIST_FAILED,

  LAUNCHER_GET_DETAIL_REQUEST,
  LAUNCHER_GET_DETAIL_SUCCESS,
  LAUNCHER_GET_DETAIL_FAILED,

  LAUNCHER_CREATE_REQUEST,
  LAUNCHER_CREATE_SUCCESS,
  LAUNCHER_CREATE_FAILED,

  LAUNCHER_UPDATE_REQUEST,
  LAUNCHER_UPDATE_SUCCESS,
  LAUNCHER_UPDATE_FAILED,

  LAUNCHER_DELETE_REQUEST,
  LAUNCHER_DELETE_SUCCESS,
  LAUNCHER_DELETE_FAILED
}
