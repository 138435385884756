import { Reducer } from 'redux'

import ActionTypes from '../../constants/action-types'
import IAuthState from '../../interfaces/states/auth'
import { IRule } from '../../interfaces/models/admin'

const initialState: IAuthState = {
  activeSub: [],
  requesting: false,
  ruleActivePage: { ...({} as IRule) }
}

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.Auth.GET_CURENT_USER_REQUEST:
    case ActionTypes.Auth.SIGN_IN_WITH_EMAIL_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.Auth.SIGN_IN_WITH_EMAIL_SUCCESS: {
      return {
        ...state,
        requesting: false,
        error: undefined,
        data: action.payload.data
      }
    }
    case ActionTypes.Auth.GET_CURENT_USER_FAILED:
    case ActionTypes.Auth.SIGN_IN_WITH_EMAIL_FAILED: {
      return {
        ...state,
        requesting: false,
        error: action.payload.error
      }
    }
    case ActionTypes.Auth.SIGN_OUT_SUCCESS: {
      return { state: initialState }
    }
    case ActionTypes.Auth.SET_MENU_SUCCESS: {
      return {
        ...state,
        activeSub: action.payload.sub
      }
    }
    case ActionTypes.Auth.GET_CURENT_USER_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        requesting: false,
        error: undefined,
        data
      }
    }
    case ActionTypes.Auth.SET_RULE_ACTIVE_PAGE: {
      const { rule } = action.payload
      return {
        ...state,
        ruleActivePage: rule
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
