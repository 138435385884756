import { query_params } from '../../../utils/services'
import Base from '../base'
const baseUrl = '/account/v1'
const pathUrl = 'admin/news'

export const GetListNews = (
  page: number,
  limit: number,
  gameCodes?: string
): Promise<any> => {
  const params = query_params({
    page,
    limit,
    gameCodes
  })

  return Base(baseUrl).get(`${pathUrl}?${params}`)
}

export const GetDetailNews = (id: number): Promise<any> => {
  return Base(baseUrl).get(`${pathUrl}/${id}`)
}

export const CreateNews = (params: any): Promise<any> => {
  return Base(baseUrl).post(pathUrl, params)
}

export const EditNews = (params: any, paramsId: number): Promise<any> => {
  return Base(baseUrl).put(pathUrl + '/' + paramsId, params)
}

export const DeleteNews = (id: number): Promise<any> => {
  return Base(baseUrl).delete(pathUrl + '/' + id)
}
