const SIGN_IN_WITH_EMAIL_REQUEST = 'SIGN_IN_WITH_EMAIL_REQUEST'
const SIGN_IN_WITH_EMAIL_SUCCESS = 'SIGN_IN_WITH_EMAIL_SUCCESS'
const SIGN_IN_WITH_EMAIL_FAILED = 'SIGN_IN_WITH_EMAIL_FAILED'

const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS'

const SET_MENU_SUCCESS = 'SET_MENU_SUCCESS'

const GET_CURENT_USER_REQUEST = 'GET_CURENT_USER_REQUEST'
const GET_CURENT_USER_SUCCESS = 'GET_CURENT_USER_SUCCESS'
const GET_CURENT_USER_FAILED = 'GET_CURENT_USER_FAILED'

const SET_RULE_ACTIVE_PAGE = 'SET_RULE_ACTIVE_PAGE'

export default {
  SIGN_IN_WITH_EMAIL_REQUEST,
  SIGN_IN_WITH_EMAIL_SUCCESS,
  SIGN_IN_WITH_EMAIL_FAILED,

  SIGN_OUT_SUCCESS,

  SET_MENU_SUCCESS,

  GET_CURENT_USER_REQUEST,
  GET_CURENT_USER_SUCCESS,
  GET_CURENT_USER_FAILED,

  SET_RULE_ACTIVE_PAGE
}
