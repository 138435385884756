/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Button, Table, Modal } from 'antd'
import React from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import ILauncherState from '../../../interfaces/states/launcher'
import ILauncher from '../../../interfaces/models/launcher'
import LauncherColumns from '../../../constants/table/launcher'
import IAuthState from '../../../interfaces/states/auth'
import ButtonAction from '../../../components/button-action'
import USER_ACTION from '../../../constants/user-action'

interface IProps {
  auth: IAuthState
  launcher: ILauncherState
  gameCode?: string
  countryCode?: string
  GetList: (
    gameCode?: string,
    countryCode?: string,
    page?: number,
    limit?: number
  ) => Promise<any>
  Delete: (id: number) => Promise<any>
}

const LauncherList = (props: IProps) => {
  const { launcher, gameCode, countryCode, auth, GetList, Delete } = props
  const history = useHistory()

  const onCreateBanner = () => {
    history.push('/launcher/id/create')
  }

  return (
    <>
      <Row gutter={16} justify='end' className='mb-2'>
        <Col>
          <ButtonAction
            action={USER_ACTION.CREATE}
            level={auth.ruleActivePage.level}
            onClick={() => onCreateBanner()}
            type='primary'
            style={{
              borderRadius: '8px'
            }}
          >
            <PlusOutlined />
            Add Launcher
          </ButtonAction>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={launcher?.requesting}
            columns={[
              ...LauncherColumns,
              {
                title: 'Action',
                dataIndex: 'id',
                key: 'action',
                align: 'center',
                render: (_t, r: ILauncher) => (
                  <Row gutter={[8, 8]} align='middle' justify='center'>
                    <Col>
                      <ButtonAction
                        level={auth.ruleActivePage.level}
                        action={USER_ACTION.DELETE}
                        ghost
                        className='border-danger-main text-color-danger-main'
                        onClick={() => {
                          Modal.confirm({
                            content:
                              'Are you sure want to delete this launcher?',
                            onOk: () => Delete(r.id)
                          })
                        }}
                      >
                        Delete
                      </ButtonAction>
                    </Col>
                    <Col>
                      <Button
                        type='primary'
                        href={`/launcher/${r.countryCode}/${r.id}`}
                      >
                        Details
                      </Button>
                    </Col>
                  </Row>
                )
              }
            ]}
            dataSource={launcher?.list || []}
            rowKey={(wr: ILauncher) => wr.id}
            pagination={{
              hideOnSinglePage: true,
              showLessItems: true,
              pageSize: launcher?.paging?.limit || 0,
              total: launcher?.paging?.totalData
            }}
            onChange={(page) => {
              GetList(
                gameCode,
                countryCode,
                page.current || 1,
                launcher.paging?.limit
              )
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default LauncherList
