import { GetListCoinLog, GetDetailCoinLog, CreateCoinLog } from './coin-log'
import { GetListGoldLog, GetDetailGoldLog, CreateGoldLog } from './gold-log'
import {
  GetListGachaTokenLog,
  GetDetailGachaTokenLog,
  CreateGachaLog
} from './gacha-token-log'

import { CreateGame, GetDetailGame, GetListGame, DeleteGame } from './game'
import {
  CreateNews,
  GetDetailNews,
  GetListNews,
  DeleteNews,
  EditNews
} from './news'
import Banner from './banner'

export default {
  GetListCoinLog,
  GetDetailCoinLog,
  CreateCoinLog,
  GetListGoldLog,
  GetDetailGoldLog,
  CreateGoldLog,
  GetListGachaTokenLog,
  GetDetailGachaTokenLog,
  CreateGachaLog,
  Banner,
  CreateGame,
  GetDetailGame,
  GetListGame,
  DeleteGame,
  CreateNews,
  GetDetailNews,
  GetListNews,
  DeleteNews,
  EditNews
}
