import React from 'react'
import { Image, TableColumnProps } from 'antd'
import { convertUnixTimestampToDate } from '../../utils/date'
import ILauncher from '../../interfaces/models/launcher'

const LauncherColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Country Code',
    dataIndex: 'countryCode',
    key: 'countryCode'
  },
  {
    title: 'Game Code',
    dataIndex: 'gameCode',
    key: 'gameCode'
  },
  {
    title: 'Active From',
    dataIndex: ['content', 'launcherImage'],
    key: 'launcherImage',
    render: (_t, r: ILauncher) => {
      return (
        <Image
          src={r?.content?.launcherImage}
          width={200}
          height={100}
          className='object-fit-cover'
        />
      )
    }
  },
  {
    title: 'Active From',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val) => convertUnixTimestampToDate(val)
  }
] as TableColumnProps<any>[]
export default LauncherColumns
