import Auth from './auth'
import User from './user'
import Upload from './upload'
import Metadata from './metadata'
import Shop from './shop'
import Account from './account'
import Launcher from './launcher'

export default {
  Auth,
  User,
  Upload,
  Metadata,
  Shop,
  Account,
  Launcher
}
