import { Editor } from '@tinymce/tinymce-react'
import React from 'react'
import Env from '../../utils/environment'

interface IProps {
  value?: any
  id?: string
  onChange?: (value) => void
  placeholder?: string
  config?: {
    stepsParser: boolean
  }
}

const formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'link']

const CustomEditor = (props: IProps) => {
  const { value, id, placeholder, onChange } = props

  const onEditorChange = (value: any) => {
    if (onChange) {
      onChange(value)
    }
  }

  if (value !== null) {
    return (
      <Editor
        onEditorChange={onEditorChange}
        id={id}
        value={value || ''}
        apiKey={Env.TINY_API_KEY}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'image',
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'image |' +
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
    )
  }
  return null
}

export default CustomEditor
