import { Button, Modal, Row } from 'antd'
import React, { useRef, useState } from 'react'

interface IProps {
  videoSrc: any
}

const VideoPreview = ({ videoSrc }: IProps) => {
  const [preview, setPreview] = useState<boolean>(false)
  const videoRef: any = useRef(null)

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current?.pause() // Pauses the video
    }
  }

  const onClosePreview = () => {
    setPreview(false)
    handlePause()
  }

  return (
    <>
      <Button
        size='small'
        type='text'
        onClick={() => {
          setPreview(true)
        }}
      >
        Preview
      </Button>
      <Modal
        visible={preview}
        width={800}
        closeIcon={<></>}
        onCancel={() => onClosePreview()}
        footer={null}
      >
        <video ref={videoRef} src={videoSrc} width={'100%'} controls />
        <Row justify='end' className='mt-1'>
          <Button type='primary' onClick={() => onClosePreview()}>
            Ok
          </Button>
        </Row>
      </Modal>
    </>
  )
}

export default VideoPreview
