import { Reducer } from 'redux'

import ActionTypes from '../../constants/action-types'
import BaseStateDefault from '../../constants/state'
import {
  IGoldTransferState,
  IPurchaseHistoryState,
  IGoldLogState,
  IGachaTokenLogState,
  IMilestoneHistoryState,
  ICoinLogState
} from '../../interfaces/states/transaction'

const initialState: IGoldTransferState = {
  ...BaseStateDefault
}

export const transaction: Reducer<any> = (
  state: IGoldTransferState = initialState,
  action: any
): IGoldTransferState => {
  switch (action.type) {
    case ActionTypes.TransactionLog.ADMIN_GOLD_TRANSFER_REQUEST:
    case ActionTypes.TransactionLog.ADMIN_GOLD_TRANSFER_DETAIL_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.TransactionLog.ADMIN_GOLD_TRANSFER_FAILED:
    case ActionTypes.TransactionLog.ADMIN_GOLD_TRANSFER_FAILED:
    case ActionTypes.TransactionLog.ADMIN_GOLD_TRANSFER_DETAIL_FAILED: {
      return {
        ...state,
        requesting: false,
        error: action.payload.error
      }
    }
    case ActionTypes.TransactionLog.ADMIN_GOLD_TRANSFER_SUCCESS: {
      const { list, paging } = action.payload
      return {
        ...state,
        requesting: false,
        list: list ? list : [],
        paging: paging
      }
    }

    case ActionTypes.TransactionLog.ADMIN_GOLD_TRANSFER_DETAIL_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        requesting: false,
        list: [...(state.list || [])],
        data: data
      }
    }
    default: {
      return state
    }
  }
}

const initialStatePurchaseHistory: IPurchaseHistoryState = {
  ...BaseStateDefault
}

export const purchaseHistory: Reducer<any> = (
  state: IPurchaseHistoryState = initialStatePurchaseHistory,
  action: any
): IPurchaseHistoryState => {
  switch (action.type) {
    case ActionTypes.TransactionLog.ADMIN_PURCHASE_HISTORY_DETAIL_REQUEST:
    case ActionTypes.TransactionLog.ADMIN_PURCHASE_HISTORY_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.TransactionLog.ADMIN_PURCHASE_HISTORY_DETAIL_FAILED:
    case ActionTypes.TransactionLog.ADMIN_PURCHASE_HISTORY_FAILED: {
      return {
        ...state,
        requesting: false,
        error: action.payload.error
      }
    }
    case ActionTypes.TransactionLog.ADMIN_PURCHASE_HISTORY_SUCCESS: {
      const { list, paging } = action.payload
      return {
        ...state,
        requesting: false,
        list: list ? list : [],
        paging: paging
      }
    }
    case ActionTypes.TransactionLog.ADMIN_PURCHASE_HISTORY_DETAIL_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        requesting: false,
        data: data
      }
    }
    default: {
      return state
    }
  }
}

const initialStateGoldLog: IGoldLogState = {
  ...BaseStateDefault
}

export const goldLog: Reducer<any> = (
  state: IGoldLogState = initialStateGoldLog,
  action: any
): IGoldLogState => {
  switch (action.type) {
    case ActionTypes.TransactionLog.ADMIN_GOLD_CREATE_REQUEST:
    case ActionTypes.TransactionLog.ADMIN_GOLD_LOG_DETAIL_REQUEST:
    case ActionTypes.TransactionLog.ADMIN_GOLD_LOG_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.TransactionLog.ADMIN_GOLD_CREATE_FAILED:
    case ActionTypes.TransactionLog.ADMIN_GOLD_LOG__DETAIL_FAILED:
    case ActionTypes.TransactionLog.ADMIN_GOLD_LOG_FAILED: {
      return {
        ...state,
        requesting: false,
        error: action.payload.error
      }
    }
    case ActionTypes.TransactionLog.ADMIN_GOLD_LOG_SUCCESS: {
      const { list, paging } = action.payload
      return {
        ...state,
        requesting: false,
        list: list ? list : [],
        paging: paging
      }
    }
    case ActionTypes.TransactionLog.ADMIN_GOLD_LOG_DETAIL_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        requesting: false,
        data: data
      }
    }

    case ActionTypes.TransactionLog.ADMIN_GOLD_CREATE_SUCCESS: {
      const { data, success } = action.payload
      return {
        ...state,
        success: success,
        requesting: false,
        list: [data, ...(state.list || [])]
      }
    }
    default: {
      return state
    }
  }
}

const initialStateCoinLog: ICoinLogState = {
  ...BaseStateDefault
}

export const coinLog: Reducer<any> = (
  state: ICoinLogState = initialStateCoinLog,
  action: any
): ICoinLogState => {
  switch (action.type) {
    case ActionTypes.TransactionLog.ADMIN_COIN_CREATE_REQUEST:
    case ActionTypes.TransactionLog.ADMIN_COIN_LOG_DETAIL_REQUEST:
    case ActionTypes.TransactionLog.ADMIN_COIN_LOG_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.TransactionLog.ADMIN_COIN_CREATE_FAILED:
    case ActionTypes.TransactionLog.ADMIN_COIN_LOG__DETAIL_FAILED:
    case ActionTypes.TransactionLog.ADMIN_COIN_LOG_FAILED: {
      return {
        ...state,
        requesting: false,
        error: action.payload.error
      }
    }
    case ActionTypes.TransactionLog.ADMIN_COIN_LOG_SUCCESS: {
      const { list, paging } = action.payload
      return {
        ...state,
        requesting: false,
        list: list ? list : [],
        paging: paging
      }
    }

    case ActionTypes.TransactionLog.ADMIN_COIN_CREATE_SUCCESS: {
      const { data, success } = action.payload
      return {
        ...state,
        success: success,
        requesting: false,
        list: [data, ...(state.list || [])]
      }
    }

    case ActionTypes.TransactionLog.ADMIN_COIN_LOG_DETAIL_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        requesting: false,
        data: data
      }
    }
    default: {
      return state
    }
  }
}

const initialStateGachaLog: IGachaTokenLogState = {
  ...BaseStateDefault
}

export const gachaTokenLog: Reducer<any> = (
  state: IGachaTokenLogState = initialStateGachaLog,
  action: any
): IGachaTokenLogState => {
  switch (action.type) {
    case ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_CREATE_REQUEST:
    case ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_LOG_REQUEST:
    case ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_LOG_DETAIL_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_CREATE_FAILED:
    case ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_LOG_FAILED:
    case ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_LOG_DETAIL_FAILED: {
      return {
        ...state,
        requesting: false,
        error: action.payload.error
      }
    }
    case ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_LOG_SUCCESS: {
      const { list, paging } = action.payload
      return {
        ...state,
        requesting: false,
        list: list ? list : [],
        paging: paging
      }
    }

    case ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_CREATE_SUCCESS: {
      const { data, success } = action.payload
      return {
        ...state,
        success: success,
        requesting: false,
        list: [data, ...(state.list || [])]
      }
    }

    case ActionTypes.TransactionLog.ADMIN_GACHA_TOKEN_LOG_DETAIL_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        requesting: false,
        data: data
      }
    }
    default: {
      return state
    }
  }
}

const initialStateMilestoneHistory: IMilestoneHistoryState = {
  ...BaseStateDefault
}

export const milestoneHistory: Reducer<any> = (
  state: IMilestoneHistoryState = initialStateMilestoneHistory,
  action: any
): IMilestoneHistoryState => {
  switch (action.type) {
    case ActionTypes.TransactionLog.ADMIN_MILESTONE_REQUEST:
    case ActionTypes.TransactionLog.ADMIN_MILESTONE_DETAIL_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.TransactionLog.ADMIN_MILESTONE_DETAIL_FAILED:
    case ActionTypes.TransactionLog.ADMIN_MILESTONE_FAILED: {
      return {
        ...state,
        requesting: false,
        error: action.payload.error
      }
    }
    case ActionTypes.TransactionLog.ADMIN_MILESTONE_SUCCESS: {
      const { list, paging } = action.payload
      return {
        ...state,
        requesting: false,
        list: list ? list : [],
        paging: paging
      }
    }
    case ActionTypes.TransactionLog.ADMIN_MILESTONE_DETAIL_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        requesting: false,
        data: data
      }
    }
    default: {
      return state
    }
  }
}
