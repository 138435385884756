/* eslint-disable react-hooks/exhaustive-deps */
/*eslint no-case-declarations: 0*/
import {
  Button,
  Card,
  Col,
  ColProps,
  DatePicker,
  Form,
  Input,
  Modal,
  ModalProps,
  Row,
  RowProps,
  Select,
  Space,
  Upload
} from 'antd'
import { FormProps } from 'antd/lib/form'
import React, { useEffect, useState } from 'react'

import { IField } from '../../interfaces/models/form'
import JsonEditor from '../json-editor'
import {
  CloseOutlined,
  DeleteOutlined,
  FileAddOutlined,
  FileImageOutlined
} from '@ant-design/icons'
import { findValueByPath } from '../../utils'
import _, { isArray } from 'lodash'
import { createObjectUrl } from '../../utils'
import VideoPreview from './video-preview'

interface INamePathImage {
  name: any
  namePath: any
}

export interface IForm extends FormProps, ModalProps {
  fields: Array<IField>
  formName: string
  onFailed?: (data: any) => void
  initialValues?: any
  visible?: boolean
  onCancel?: () => void
  title?: string
  additionalHeader?: React.ReactNode
  additionalElementForm?: React.ReactNode
  okText?: string
  cancelText?: string
  width?: number
  confirmLoading?: boolean
  useModal?: boolean
  onValuesChange?: (changedValues: any, values: any) => void
  rowProps?: RowProps
  colProps?: ColProps
  additionalFieldForm?: React.ReactNode
}

const { Option } = Select
const { confirm } = Modal

const DefaultForm = ({
  fields,
  formName,
  onFinish,
  onFailed,
  labelCol,
  labelAlign,
  wrapperCol,
  initialValues,
  additionalElementForm,
  onValuesChange,
  rowProps,
  colProps,
  additionalFieldForm
}: IForm) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
  }, [initialValues])

  const onFinishForm = (values: any) => {
    confirm({
      title: 'Are you sure you want to proceed this form?',
      onOk: () => onFinish && onFinish(values),
      onCancel: () => null
    })
  }

  const onFinishFailed = (values: any) => {
    onFailed && onFailed(values)
  }

  const handleNormFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return _.isEmpty(e.fileList) ? undefined : e && [e.file]
  }

  const getValueByPath = (namePath: any) =>
    findValueByPath(initialValues, namePath)

  const renderInput = (f: IField, namePathImage?: INamePathImage) => {
    switch (f.type) {
      case 'textarea':
        return (
          <Input.TextArea
            placeholder={f.placeholder || ''}
            autoSize={{ minRows: 4 }}
            disabled={f.disabled}
          />
        )
      case 'password':
        return (
          <Input.Password
            placeholder={f.placeholder || ''}
            disabled={f.disabled}
          />
        )
      case 'select':
        // if value nested object, name: ['gender','id']
        return (
          <Select placeholder={f.placeholder} disabled={f.disabled}>
            {f.options?.map((x, key: number) => (
              <Option key={key} value={x.id}>
                {x.name}
              </Option>
            ))}
          </Select>
        )
      case 'json-editor':
        return <JsonEditor mode='code' />
      case 'date-time':
      case 'date':
        return (
          <DatePicker
            showTime={f.type === 'date-time'}
            className='w-100'
            placeholder={f.placeholder || ''}
            disabled={f.disabled}
          />
        )
      case 'image':
        let value = ''
        // mapping multiple form have field image
        if (namePathImage?.namePath) {
          if (isArray(getValueByPath(namePathImage?.namePath as any))) {
            value =
              getValueByPath(namePathImage?.namePath as any)[
                namePathImage?.name
              ]?.[f.name as any] || ''
          } else value = ''
        } else {
          value = getValueByPath(f.name as any)
        }
        return (
          <Upload
            listType='picture'
            beforeUpload={() => false}
            accept={f.accept}
            defaultFileList={
              [
                ...(value
                  ? [
                      {
                        status: 'done',
                        url: value
                      }
                    ]
                  : ([] as any))
              ] as any
            }
            maxCount={1}
          >
            <Button icon={<FileImageOutlined />}>Choose Image</Button>
          </Upload>
        )
      case 'video':
        let videoSrc = ''
        // mapping multiple form have field image
        if (namePathImage?.namePath) {
          if (isArray(getValueByPath(namePathImage?.namePath as any))) {
            videoSrc =
              getValueByPath(namePathImage?.namePath as any)[
                namePathImage?.name
              ]?.[f.name as any] || ''
          } else videoSrc = ''
        } else {
          videoSrc = getValueByPath(f.name as any)
        }
        return (
          <Upload
            listType='picture'
            beforeUpload={() => false}
            accept={'video/*'}
            defaultFileList={
              [
                ...(videoSrc
                  ? [
                      {
                        status: 'done',
                        url: videoSrc
                      }
                    ]
                  : ([] as any))
              ] as any
            }
            itemRender={(_originNode, file, _fileList, action) => {
              const url = file.url
                ? file.url
                : createObjectUrl(file.originFileObj as any)
              return (
                <div className='ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture'>
                  <Row align='middle' gutter={8}>
                    <Col flex={'1 1'}>
                      <a href={url} target='_blank' rel='noreferrer'>
                        <video
                          src={url}
                          width={40}
                          height={40}
                          style={{ objectFit: 'cover' }}
                        />
                      </a>
                    </Col>
                    <Col>
                      <VideoPreview videoSrc={url} />
                    </Col>
                    <Col>
                      <Button
                        size='small'
                        type='text'
                        onClick={() => action.remove()}
                      >
                        <DeleteOutlined
                          size={16}
                          style={{ color: '#f6274c' }}
                        />
                      </Button>
                    </Col>
                  </Row>
                </div>
              )
            }}
            maxCount={1}
          >
            <Button icon={<FileAddOutlined />}>Choose Video</Button>
          </Upload>
        )
      default:
        return (
          <Input
            type={f.type}
            placeholder={f.placeholder || ''}
            disabled={f.disabled}
          />
        )
    }
  }

  return (
    <Form
      form={form}
      name={formName}
      onFinish={onFinishForm}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      labelAlign={labelAlign || 'left'}
      labelCol={labelCol || { span: 24 }}
      wrapperCol={wrapperCol || { span: 24 }}
      initialValues={initialValues}
      requiredMark={false}
      scrollToFirstError
      size='large'
    >
      <Row {...rowProps}>
        {fields.map((f: IField, key: number) => {
          const columnProps = f.colProps || colProps

          if (f.type === 'multiple-form' && (f.fields || [])?.length > 0)
            return (
              <Col {...columnProps} key={key}>
                <div className='mb-2 multiple-form'>
                  <p className='mb-1 text-weight-semibold'>{f.label}</p>
                  <Form.List name={f.name} rules={f.rules as any}>
                    {(fields, { add, remove }) => (
                      <div
                        style={{
                          display: 'flex',
                          rowGap: 16,
                          flexDirection: 'column'
                        }}
                      >
                        {fields.map(({ key, name, ...restField }) => (
                          <Card
                            size='small'
                            title={`${f.label || f.placeholder} ${name + 1}`}
                            key={key}
                            extra={
                              <CloseOutlined
                                onClick={() => {
                                  remove(name)
                                }}
                              />
                            }
                          >
                            <Row gutter={[16, 16]}>
                              {f.fields?.map((itemField, i) => {
                                return (
                                  <Col {...itemField.colProps} key={i}>
                                    <div
                                      className={`${
                                        f.type === 'json-editor'
                                          ? 'field-json'
                                          : ''
                                      }`}
                                    >
                                      <Form.Item
                                        {...restField}
                                        name={[name, itemField.name as any]}
                                        rules={itemField.rules}
                                        getValueFromEvent={
                                          ['image', 'video'].includes(
                                            itemField.type || ''
                                          )
                                            ? handleNormFile
                                            : undefined
                                        }
                                      >
                                        {renderInput(
                                          itemField,
                                          ['image', 'video'].includes(
                                            itemField.type || ''
                                          )
                                            ? ({
                                                name,
                                                namePath: f.name as any
                                              } as any)
                                            : ''
                                        )}
                                      </Form.Item>
                                    </div>
                                  </Col>
                                )
                              })}
                            </Row>
                          </Card>
                        ))}

                        <Button type='dashed' onClick={() => add()} block>
                          + Add Item
                        </Button>
                      </div>
                    )}
                  </Form.List>
                </div>
              </Col>
            )

          return (
            <Col {...columnProps} key={key}>
              <div
                className={`${f.type === 'json-editor' ? 'field-json' : ''}`}
              >
                <Form.Item
                  {...f}
                  getValueFromEvent={
                    ['image', 'video'].includes(f.type || '')
                      ? handleNormFile
                      : undefined
                  }
                >
                  {renderInput(f)}
                </Form.Item>
              </div>
            </Col>
          )
        })}
      </Row>
      {additionalFieldForm || <></>}
      {additionalElementForm && <Form.Item>{additionalElementForm}</Form.Item>}
    </Form>
  )
}

export default DefaultForm
