const METADATA_GET_LIST_REQUEST = 'METADATA_GET_LIST_REQUEST'
const METADATA_GET_LIST_SUCCESS = 'METADATA_GET_LIST_SUCCESS'
const METADATA_GET_LIST_FAILED = 'METADATA_GET_LIST_FAILED'

const METADATA_CREATE_REQUEST = 'METADATA_CREATE_REQUEST'
const METADATA_CREATE_SUCCESS = 'METADATA_CREATE_SUCCESS'
const METADATA_CREATE_FAILED = 'METADATA_CREATE_FAILED'

const METADATA_UPDATE_REQUEST = 'METADATA_UPDATE_REQUEST'
const METADATA_UPDATE_SUCCESS = 'METADATA_UPDATE_SUCCESS'
const METADATA_UPDATE_FAILED = 'METADATA_UPDATE_FAILED'

const METADATA_DELETE_REQUEST = 'METADATA_DELETE_REQUEST'
const METADATA_DELETE_SUCCESS = 'METADATA_DELETE_SUCCESS'
const METADATA_DELETE_FAILED = 'METADATA_DELETE_FAILED'

export default {
  METADATA_GET_LIST_REQUEST,
  METADATA_GET_LIST_SUCCESS,
  METADATA_GET_LIST_FAILED,

  METADATA_CREATE_REQUEST,
  METADATA_CREATE_SUCCESS,
  METADATA_CREATE_FAILED,

  METADATA_UPDATE_REQUEST,
  METADATA_UPDATE_SUCCESS,
  METADATA_UPDATE_FAILED,

  METADATA_DELETE_REQUEST,
  METADATA_DELETE_SUCCESS,
  METADATA_DELETE_FAILED
}
