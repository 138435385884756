import { Reducer } from 'redux'

import ActionTypes from '../../constants/action-types'
import BaseStateDefault from '../../constants/state'
import { INewsUpdateState } from '../../interfaces/states/account'

const initialState: INewsUpdateState = {
  ...BaseStateDefault
}

export const newsUpdate: Reducer<any> = (
  state: INewsUpdateState = initialState,
  action: any
): INewsUpdateState => {
  switch (action.type) {
    case ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_LIST_REQUEST:
    case ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_DETAIL_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_LIST_FAILED:
    case ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_EDIT_FAILED:
    case ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_CREATE_FAILED:
    case ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_DETAIL_FAILED:
    case ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_DELETE_FAILED: {
      return {
        ...state,
        requesting: false,
        error: action?.payload?.error || 'Error'
      }
    }
    case ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_LIST_SUCCESS: {
      const { list, paging } = action.payload
      return {
        ...state,
        requesting: false,
        list: list ? list : [],
        paging: paging
      }
    }
    case ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_DELETE_SUCCESS:
    case ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_EDIT_SUCCESS:
    case ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_CREATE_SUCCESS:
    case ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_DETAIL_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        requesting: false,
        data: data
      }
    }
    default: {
      return state
    }
  }
}
