const ADMIN_NEWSUPDATE_LIST_REQUEST = 'ADMIN_NEWSUPDATE_LIST_REQUEST'
const ADMIN_NEWSUPDATE_LIST_SUCCESS = 'ADMIN_NEWSUPDATE_LIST_SUCCESS'
const ADMIN_NEWSUPDATE_LIST_FAILED = 'ADMIN_NEWSUPDATE_LIST_FAILED'

const ADMIN_NEWSUPDATE_CREATE_REQUEST = 'ADMIN_NEWSUPDATE_CREATE_REQUEST'
const ADMIN_NEWSUPDATE_CREATE_SUCCESS = 'ADMIN_NEWSUPDATE_CREATE_SUCCESS'
const ADMIN_NEWSUPDATE_CREATE_FAILED = 'ADMIN_NEWSUPDATE_CREATE_FAILED'

const ADMIN_NEWSUPDATE_EDIT_REQUEST = 'ADMIN_NEWSUPDATE_EDIT'
const ADMIN_NEWSUPDATE_EDIT_SUCCESS = 'ADMIN_NEWSUPDATE_EDIT'
const ADMIN_NEWSUPDATE_EDIT_FAILED = 'ADMIN_NEWSUPDATE_EDIT'

const ADMIN_NEWSUPDATE_DETAIL_REQUEST = 'ADMIN_NEWSUPDATE_DETAIL_REQUEST'
const ADMIN_NEWSUPDATE_DETAIL_SUCCESS = 'ADMIN_NEWSUPDATE_DETAIL_SUCCESS'
const ADMIN_NEWSUPDATE_DETAIL_FAILED = 'ADMIN_NEWSUPDATE_DETAIL_FAILED'

const ADMIN_NEWSUPDATE_DELETE_REQUEST = 'ADMIN_NEWSUPDATE_DELETE_REQUEST'
const ADMIN_NEWSUPDATE_DELETE_SUCCESS = 'ADMIN_NEWSUPDATE_DELETE_SUCCESS'
const ADMIN_NEWSUPDATE_DELETE_FAILED = 'ADMIN_NEWSUPDATE_DELETE_FAILED'

export default {
  ADMIN_NEWSUPDATE_CREATE_REQUEST,
  ADMIN_NEWSUPDATE_CREATE_SUCCESS,
  ADMIN_NEWSUPDATE_CREATE_FAILED,
  ADMIN_NEWSUPDATE_DELETE_REQUEST,
  ADMIN_NEWSUPDATE_DELETE_SUCCESS,
  ADMIN_NEWSUPDATE_DELETE_FAILED,
  ADMIN_NEWSUPDATE_DETAIL_REQUEST,
  ADMIN_NEWSUPDATE_DETAIL_SUCCESS,
  ADMIN_NEWSUPDATE_DETAIL_FAILED,
  ADMIN_NEWSUPDATE_EDIT_REQUEST,
  ADMIN_NEWSUPDATE_EDIT_SUCCESS,
  ADMIN_NEWSUPDATE_EDIT_FAILED,
  ADMIN_NEWSUPDATE_LIST_REQUEST,
  ADMIN_NEWSUPDATE_LIST_FAILED,
  ADMIN_NEWSUPDATE_LIST_SUCCESS
}
