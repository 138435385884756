import { combineReducers } from 'redux'

import IAuthState from '../../interfaces/states/auth'
import IUserState from '../../interfaces/states/user'
import IUploadState from '../../interfaces/states/upload'

import auth from './auth'
import user from './user'
import upload from './upload'
import metadata from './metadata'
import { banner } from './banner'
import IMetadataState from '../../interfaces/states/metadata'
import {
  transaction,
  purchaseHistory,
  goldLog,
  gachaTokenLog,
  milestoneHistory,
  coinLog
} from './transaction'
import {
  ICoinLogState,
  IGachaTokenLogState,
  IGoldLogState,
  IGoldTransferState,
  IMilestoneHistoryState,
  IPurchaseHistoryState
} from '../../interfaces/states/transaction'
import {
  IBannerState,
  IGameState,
  INewsUpdateState
} from '../../interfaces/states/account'
import { game } from './game'
import { newsUpdate } from './news'
import ILauncherState from '../../interfaces/states/launcher'
import launcher from './launcher'

export interface ReduxState {
  auth: IAuthState
  user: IUserState
  upload: IUploadState
  metadata: IMetadataState
  transaction: IGoldTransferState
  purchaseHistory: IPurchaseHistoryState
  goldLog: IGoldLogState
  coinLog: ICoinLogState
  gachaTokenLog: IGachaTokenLogState
  milestoneHistory: IMilestoneHistoryState
  banner: IBannerState
  game: IGameState
  newsUpdate: INewsUpdateState
  launcher: ILauncherState
}

export const rootReducer = combineReducers<ReduxState>({
  auth,
  user,
  upload,
  metadata,
  transaction,
  purchaseHistory,
  goldLog,
  gachaTokenLog,
  milestoneHistory,
  coinLog,
  banner,
  game,
  newsUpdate,
  launcher
})
