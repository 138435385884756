import { Button, Col, Form, Row } from 'antd'
import React from 'react'

export default function CreateForm({
  onSubmit,
  layout,
  style,
  FormItem,
  form
}: any) {
  return (
    <Form
      labelCol={{ flex: '130px' }}
      labelAlign='left'
      wrapperCol={{ flex: 1 }}
      colon={false}
      style={style}
      onFinish={onSubmit}
      form={form}
      name='transaction'
      scrollToFirstError
      layout={layout}
    >
      {FormItem.map((value: IFormItem) => {
        return (
          <Form.Item
            key={value.name}
            name={value.name}
            label={value.label}
            rules={value.rules}
          >
            {value.field}
          </Form.Item>
        )
      })}
      <Row gutter={[10, 10]} justify='end'>
        <Col>
          <Button type='ghost' style={{ borderRadius: '5px' }}>
            Save as draft
          </Button>
        </Col>
        <Col>
          <Button
            type='primary'
            htmlType='submit'
            style={{ borderRadius: '5px' }}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

interface IFormItem {
  name: string
  label: string
  field: JSX.Element
  rules?: any[]
  placeholder?: string
}
