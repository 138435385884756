const env = process.env.REACT_APP_ADMIN_STAGE || 'local'

const CONFIGS = {
  LOCAL: {
    API_URL: 'https://api-gtw.dev.noctua.gg',
    SENTRY_ENABLE: false,
    IS_DEBUG: true,
    STORAGE_SUFFIX: '_local',
    STORAGE_DOMAIN: 'localhost',
    WEB_FRAGMENT_URL: 'https://dev.noctua.gg',
    TINY_API_KEY: 'nqrdawf92km0iunaorye27s62qe7dmy1cmu5dlvm84i8hivc'
  },
  DEV: {
    API_URL: 'https://api-gtw.dev.noctua.gg',
    SENTRY_ENABLE: false,
    IS_DEBUG: false,
    STORAGE_SUFFIX: '_dev',
    STORAGE_DOMAIN: '.dev.noctua.gg',
    WEB_FRAGMENT_URL: 'https://dev.noctua.gg',
    TINY_API_KEY: 'nqrdawf92km0iunaorye27s62qe7dmy1cmu5dlvm84i8hivc'
  },
  PROD: {
    API_URL: 'https://api-gtw.noctua.gg',
    SENTRY_ENABLE: false,
    IS_DEBUG: false,
    STORAGE_SUFFIX: '',
    STORAGE_DOMAIN: 'noctua.gg',
    WEB_FRAGMENT_URL: 'https://noctua.gg',
    TINY_API_KEY: 'nqrdawf92km0iunaorye27s62qe7dmy1cmu5dlvm84i8hivc'
  }
}

let CONFIG = CONFIGS.LOCAL
if (env === 'development') {
  CONFIG = CONFIGS.DEV
}
if (env === 'production') {
  CONFIG = CONFIGS.PROD
}

export default CONFIG
