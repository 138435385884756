enum ORDER_STATUS {
  DELIVERED = 'delivered',
  PAID = 'paid',
  FAILED = 'failed',
  PENDING = 'pending',
  EXPIRED = 'expired',
  CANCELED = 'canceled'
}

export const ORDER_STATUS_OPTIONS = [
  ORDER_STATUS.DELIVERED,
  ORDER_STATUS.PAID,
  ORDER_STATUS.FAILED,
  ORDER_STATUS.EXPIRED,
  ORDER_STATUS.CANCELED,
  ORDER_STATUS.PENDING
]

export default ORDER_STATUS
