import { IResult } from '@attn/general/dist/interfaces/api'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../../constants/action-types'
import Api from '../../apis'
import Base from '../base'
import { INewsUpdateState } from '../../../interfaces/states/account'
import { message } from 'antd'
import {
  ICreateLog,
  IRequestList
} from '../../../interfaces/request/transaction'

export const GetListNews =
  ({ page, limit, gameCodes }: IRequestList) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.GetListNews(page, limit, gameCodes),
      () => {
        dispatch(action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_LIST_REQUEST))
      },
      (res: IResult<Array<INewsUpdateState>>) => {
        dispatch(
          action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_LIST_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_LIST_FAILED, {
            error
          })
        )
    )
  }

export const GetDetailNews =
  (id: number) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.GetDetailNews(id),
      () => {
        dispatch(action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_DETAIL_REQUEST))
      },
      (res: IResult<INewsUpdateState>) => {
        dispatch(
          action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_DETAIL_SUCCESS, {
            data: res.data,
            success: true
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_DETAIL_FAILED, {
            error
          })
        )
    )
  }

export const CreateNews =
  (data: ICreateLog) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.CreateNews(data),
      () => {
        dispatch(action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_CREATE_REQUEST))
      },
      (res: IResult<INewsUpdateState>) => {
        dispatch(
          action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_CREATE_SUCCESS, {
            data: res.data,
            success: true
          })
        )
        message.success('Create News successfully')
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_CREATE_FAILED, {
            error
          })
        )
    )
  }

export const EditNews =
  (data: ICreateLog, paramsId: number) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Account.EditNews(data, paramsId),
      () => {
        dispatch(action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_EDIT_REQUEST))
      },
      (res: IResult<INewsUpdateState>) => {
        dispatch(
          action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_EDIT_SUCCESS, {
            data: res.data,
            success: true
          })
        )
        message.success('Edit News successfully')
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_EDIT_FAILED, {
            error
          })
        )
    )
  }

export const DeleteNews = (id: number) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Account.DeleteNews(id),
    () => {
      dispatch(action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_DELETE_REQUEST))
    },
    (res: IResult<INewsUpdateState>) => {
      dispatch(
        action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_DELETE_SUCCESS, {
          data: res.data,
          success: true
        })
      )
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.NewsUpdate.ADMIN_NEWSUPDATE_DELETE_FAILED, {
          error
        })
      )
  )
}
