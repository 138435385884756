/* eslint-disable react-hooks/exhaustive-deps */
import Layout from '../../components/layout'
import RoutesLabel from '../../constants/enum/route-name'
import ReportList from './list'
import React from 'react'

export default function NewsAndReport() {
  return (
    <Layout
      header={{ title: RoutesLabel.NewsAndReport }}
      breadcrumbs={[{ label: 'News And Updates' }]}
    >
      <ReportList filter title='News And Updates' />
    </Layout>
  )
}
